import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { useEffect, useState } from "react";
import { fetchProducts } from "../../redux/actions/products/productsAction";
import ProductList from "../ProductList/ProductList";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { fetchSubCategoryById } from "../../redux/actions/categories/categoryAction";
import tcWrap from '../../utilities/generic-helpers/tcWrap';
import {apiClient} from '../../api/client';

const RightPanel = ({ subCategoryPage, setSubCategoryPage, subCategory }) => {
  var settings = {
    // arrows:true,
    dots: false,
    infinite: false,
    arrows: false,
    autoplay: true,
    speed: 500,
    slidesToScroll: 2,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const { loading, has_more } = useSelector((state) => state.categories);
  const { products , hasmore } = useSelector((state) => state?.products);
  const [selectedId, setSelectedId] = useState(subCategory?.items[0]?.id);
  const [refresh, setRefresh] = useState(false);
  const [productsPage,setProductPage] = useState(1);
  const [productsList,setProductList]=useState({
    items:[],
    has_more:false
  })

  const categoryId = subCategory?.items[0]?.parent_id;
  const subCategoryId = selectedId || subCategory?.items[0]?.id;
  console.log(categoryId,subCategoryId,"testingn")

  const [selectedSubCategoryName, setselectedSubCategoryName] = useState(subCategory?.items[0]?.name);
  const dispatch = useDispatch();

  const getProducts = async () => {
    const formData = new FormData();
    formData.append("category_id", categoryId);
    formData.append("sub_category_id", subCategoryId);
    formData.append("page", productsPage);
    try {
      tcWrap(async () => {
        const data = await apiClient.post("/general/product_listing", formData)
        if(productsPage > 1) {
          setProductList((p)=>({...p,items: productsList?.items?.concat(data?.data)}))
        }
        else{
          setProductList((p)=>({...p,items: data?.data}))
        }
        setProductList((p)=>({...p,has_more:data?.has_more}))
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubCategoryClick = (id, name) => {
    setSelectedId(id);
    setselectedSubCategoryName(name);
    setRefresh((prevState) => !prevState); // Toggle refresh state to force re-render
  };
  useEffect(() => {
    if (subCategoryId) {
      // If subCategoryId exists, set the page to 1 and then get products.
      setProductPage(1, () => {
        getProducts();
      });
    }
  }, [subCategoryId]);

  useEffect(() => {
    // This useEffect sets selectedId and selectedSubCategoryName based on subCategory.
    setSelectedId(subCategory?.items[0]?.id);
    setselectedSubCategoryName(subCategory?.items[0]?.name);
  }, [subCategory]);

  useEffect(() => {
    // This useEffect fetches products when categoryId or subCategoryId changes.
    if (categoryId && subCategoryId) {
      getProducts();
    }
  }, [categoryId, subCategoryId, productsPage]);


  // useEffect(() => {
  //   if (categoryId && subCategoryId) {
  //     getProducts();
  //   }
  // }, [categoryId, subCategoryId, refresh]);
  return (
    <div className="right-panel-wrapper">
      {loading && <Skeleton width={150} height={30} />}
      {/* {!loading && subCategory.length <= 0 && <div> No Records Found</div>} */}
      {!loading && selectedId != undefined && subCategory?.items?.length > 0 && (
        <ul className="d-flex  category-tabs">
          <Slider {...settings} className="d-flex  category-tabs">
            {subCategory?.items?.map((item) => {
              return (
                <li
                  className={`category-tab-item  mt-4 ${selectedId === item?.id ? "active" : ""}`}
                  key={item?.id}
                  onClick={() => handleSubCategoryClick(item?.id, item?.name)}
                  style={{ cursor: "pointer" }}
                >
                  <div className="category-tab-link">{item?.name}</div>
                </li>
              );
            })}

             {subCategory?.has_more === true && (
              <li
                className={`category-tab-item  mt-4 `}
                onClick={(e) => {
                  e.preventDefault();
                  setSubCategoryPage((prev) => prev + 1);
                }}
              >
                {" "}
                see More
              </li>
            )}
          </Slider>
        </ul>
      )}
      <ProductList productsList={productsList} hasmore={hasmore} productsPage={productsPage} setProductPage={setProductPage} products={products} selectedSubCategoryName={selectedSubCategoryName} selectedId={selectedId} />
    </div>
  );
};
export default RightPanel;
