import React from 'react'

const HeroBannerSlide = ({src, alt, title, width, height}) => {
  return (
    <div className='hero-banner-img'>
      <img src={src} alt={alt} title={title} width={width} height={height} />
    </div>
  )
}

export default HeroBannerSlide
