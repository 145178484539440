import moment from 'moment';
import React from "react";
import { Card, Badge } from "react-bootstrap";

const NotificationCard = ({ date, title, message }) => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h6 className="mb-0">{title}</h6>
          <Badge variant="info">{moment(date).format('DD-MM-YY')}</Badge>
        </div>
        <p className="mb-0">{message}</p>
      </Card.Body>
    </Card>
  );
};

export default NotificationCard;
