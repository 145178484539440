import { apiClient } from "../client";
import handleError from "../../utilities/api-helpers/handleError";
import tcWrap from "../../utilities/generic-helpers/tcWrap";
import { removeAuth } from "../../utilities/auth-helpers/authHelpers";

export const userLogout = () =>
  tcWrap(async () => {
    const response = await apiClient.get("/user/logout").catch(handleError);
    if (response?.status) removeAuth();
    return response.message;
  });
