import React from "react";
import NotFoundImg from "../../assets/images/page-not-found-404.jpg";
import PrimaryButton from "../../common/Button/PrimaryButton";
import {useNavigate} from 'react-router-dom'

const NotFound = () => {

  const navigate = useNavigate()
  return (
    <>
      <>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <img style={{ height: "500px" }} alt="not-found" src={NotFoundImg} />
        <PrimaryButton
          type="submit"
          onClick={()=>navigate("/")}
          style={{ backgroundColor: "#F8A11F", border: "#F8A11F", marginTop: "20px" }}
          className="btn btn-primary mb-4"
        >
          Go to Home
        </PrimaryButton>
      </div>
    </>
    </>
  );
};

export default NotFound;
