import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "../../assets/images/close-icon.svg";
import Slider from "react-slick";

const ImagePreviewModal = ({ timeline, ImageModal, setImageModal, ImageModalId, setImageModalId }) => {
  const handleCloseModalImage = () => setImageModal(false);

  return (
    <Modal
      size="md"
      show={ImageModal}
      onHide={handleCloseModalImage}
      style={{
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        overflow: "hidden",
        height: "100vh",
      }}
    >
      <Modal.Body style={{ "text-align": "end" }}>
        <Button className="modal-close" onClick={handleCloseModalImage}>
          <CloseIcon />
        </Button>

        <div className="container">
          <img
            src={ImageModalId}
            className=""
            alt=""
            onClick={(e) => {
              e.preventDefault();
            }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ImagePreviewModal;
