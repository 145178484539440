import React from "react";
import { Link } from "react-router-dom";
import ImgShop from "../../assets/images/shop-icon.jpg";

const SponsoredAdSlide = ({ key, src, alt, title, width, height, url }) => {
  return (
    <>
      <Link to={url} target='blank'>
        <div className="ad-item" key={key}>
          <div className="m-b-10 ad-image">
            <img src={src} alt={alt} title={title} width={width} height={height} />
          </div>
          <Link to="/" className="m-b-20 text-left ad-title">
            {title}
          </Link>
          <div className="d-flex text-left shop-action">
            <div className="d-flex align-items-center shop-name">
                {/* <i className="m-r-10 icon">
                  <img src={ImgShop} alt="Om Kite" title="Om Kite" width={33} height={33} />
                </i> */}
              {/* <p className="m-b-0">Om Kite</p> */}
            </div>
            <div className="action-block">
              <Link to={url} target='blank' className="button-secondary">
                Ads Details
              </Link>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default SponsoredAdSlide;
