import React, { useCallback, useEffect, useRef, useState } from "react";
import RightPanel from "./RightPanel";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories } from "../../api/categories/categories";
import InfiniteScroll from "../../common/InfiniteScroll/InfiniteScroll";
import CategoryLeftSkeleton from "./CategoryLeftSkeleton";
import NoResults from "../../common/NoResults";
import { CategoryIcon } from "../../assets/icons";
import { fetchSubCategoryById } from "../../redux/actions/categories/categoryAction";

const CategoryPanel = () => {
  const [page, setPage] = useState(1);
  const [subCategoryPage, setSubCategoryPage] = useState(1);

  const { categories } = useSelector((state) => state.categories);
  const [activeCategory, setActiveCategory] = useState();
  const dispatch = useDispatch();
  const [categoriesFrom, setCategoryFrom] = useState({
    items: [],
    has_more: false,
  });
  const [subCategoryList, setSubCategoryList] = useState({
    items: [],
    has_more: false,
  });
  console.log(subCategoryList, 'subCategoryList')
  const formData = new FormData();
  formData.append("page", page);

  const getCategories = async () => {
    try {
      const res = await fetchCategories(formData);
      if (res) {
        if (page > 1) {
          setCategoryFrom((p) => ({ ...p, items: categoriesFrom?.items?.concat(res?.data) }));
        } else {
          setCategoryFrom((p) => ({ ...p, items: res?.data }));
        }
        if (res?.data) {
          setActiveCategory(res?.data[0]?.id);
        }
        setCategoryFrom((p) => ({ ...p, has_more: res?.has_more }));
      }
      console.log(res, "res");
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCategories();
  }, [page]);

  useEffect(() => {
    if (activeCategory) {
      getSubCategoryById();
    }
  }, [activeCategory, subCategoryPage]);

  const observer = useRef();

  const lastBookElementRef = useCallback(
    (node) => {
      // if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && categoriesFrom?.has_more) {
          setPage((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [categoriesFrom?.has_more]
  );

  const getSubCategoryById = async () => {
    const formData = new FormData();
    formData.append("page", subCategoryPage);
    formData.append("parent_id", activeCategory);

    try {
      if (activeCategory) {
        // await dispatch(fetchSubCategoryById(formData, subCategoryPage));
        const res = await fetchCategories(formData);
        if (subCategoryPage > 1) {
          setSubCategoryList((p) => ({ ...p, items: subCategoryList?.items?.concat(res?.data) }));
        } else {
          setSubCategoryList((p) => ({ ...p, items: res?.data }));
        }
        setSubCategoryList((p) => ({ ...p, has_more: res?.has_more }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="category-panel-section section-gap">
      <div className="container">
        <div className="row">
          <div
            id="style-1"
            className="col col-12 col-md-4 col-xl-3"
            style={{ overflowY: "scroll", maxHeight: "600px" }}
          >
            {categoriesFrom?.items.length > 0 &&
              categoriesFrom?.items.map((category) => {
                return (
                  <>
                    <div ref={lastBookElementRef}>
                      <ul
                        key={category?.id}
                        className={`list-style-none left-panel-nav`}
                        onClick={() => {
                          setActiveCategory(category?.id);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <li className={activeCategory === category?.id ? `category-item active` : `category-item`}>
                          <div className="d-flex align-items-center category-link">
                            <i className="icon m-r-26">
                              <img
                                src={category?.icon ? category?.icon : CategoryIcon}
                                alt="Category"
                                title={category?.name}
                                width={56}
                                height={56}
                              />
                            </i>
                            {category?.name}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </>
                );
              })}
          </div>
          <div className="col col-12 col-md-8 col-xl-9">
            <RightPanel setSubCategoryPage={setSubCategoryPage} subCategoryPage={subCategoryPage} subCategory={subCategoryList}  />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryPanel;
