import React, { useEffect, useRef, useState } from "react";
import CloseButton from "../Button/CloseButton";
import PrimaryButton from "../Button/PrimaryButton";
import { userLogout } from "../../api/authentication/logout";
import Spinner from "../Spinner/Spinner";
import { useDispatch } from "react-redux";
import { updateToken } from "../../redux/actions/authentication/authActions";
import { Modal } from "react-bootstrap";

const LogoutModal = ({ showLogoutModal, hide }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const closeRef = useRef();
  const token = localStorage.getItem("fs");
  const handleLogout = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await userLogout();
      setLoading(false);
      localStorage.clear()
      window.location.replace('/')
    } catch (error) {
      if(error?.status === 401){
        localStorage.clear()
        window.location.replace('/')
      }
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(updateToken(token));
    if(token === null){
        hide();
        closeRef?.current?.click()
    }
  }, [token, dispatch]);

  console.log(token,"token from logout")
  return (
    <Modal show={showLogoutModal} onHide={hide}>
      <div className="modal-content">
        <Modal.Header>
          <div className="modal-header">
            <h5 className="modal-title" id="logoutModalCenterTitle">
              Logout
            </h5>
          </div>

        </Modal.Header>
        <Modal.Body>
        <div className="modal-body">Are you sure you want to log out?</div>
          <form type="submit" onSubmit={handleLogout}>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={hide} ref={closeRef} >
                Close
              </button>
              <PrimaryButton
                type="submit"
                style={{ backgroundColor: "#F8A11F", border: "#F8A11F" }}
                onClick={handleLogout}
                disabled={loading}
                className="btn btn-primary"
              >
                {loading ? <Spinner /> : "Yes"}
              </PrimaryButton>
            </div>
          </form>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default LogoutModal;
