import React, { useEffect } from "react";
import BlogHead from "../../assets/images/blog-head.jpg";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchBlogsById } from "../../redux/actions/blogs/blogsActions";
import Skeleton from "react-loading-skeleton";

const BlogDetailsMain = () => {
  const { loading, blog } = useSelector((state) => state?.blogs);
  const dispatch = useDispatch();


  const { id } = useParams();


  const blogDetail = async () => {
    const formData = new FormData();
    formData.append("blog_id", id);
    try {
      await dispatch(fetchBlogsById(formData));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    blogDetail();
  }, []);

  return (
    <div className="blog-detail-page">
      <div className="m-b-80 page-heading page-heading--image">
        <div className="bg-image">
          <img src={BlogHead} alt="Blog Detail Landing" />
        </div>
        {loading && (
          <div className="bg-content">
            <Skeleton width={900} height={45} />
            <Skeleton width={600} height={30} style={{ marginTop: "10px" }} />
          </div>
        )}
        <div className="bg-content">
          <h2>{blog?.data?.title}</h2>
          <p>{blog?.data?.author}</p>
        </div>
      </div>
      <div className="container">
        <div className="blog-details-main section-gap">
          <div className="blog-thumb">
            {loading && <Skeleton width={554} height={396} style={{ borderRadius: "35px" }} />}
            <img src={blog?.data?.image} alt="Blog Thumb" title="Blog Thumb" width={554} height={396} />
          </div>
          {loading && <Skeleton width={700} height={30} style={{ borderRadius: "8px", marginBottom: "15px" }} />}
          <h4>{blog?.data?.title}</h4>
          {loading && <Skeleton width={550} height={150} style={{ borderRadius: "8px", marginBottom: "15px" }} />}
          {loading && <Skeleton width={700} height={30} count={6} style={{ borderRadius: "8px", marginBottom: "15px" }} />}

          <p dangerouslySetInnerHTML={{ __html: blog?.data?.description }}></p>
        </div>
      </div>
    </div>
  );
};

export default BlogDetailsMain;
