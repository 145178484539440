import React from 'react'
import AboutUsMain from '../components/AboutUs/AboutUs'

const About = () => {
  return (
    <>
    <AboutUsMain />
    </>
  )
}

export default About
