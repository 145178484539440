import React from "react";

const PrimaryDropdown = (props) => {
  const { name, value, options, onChange, className, groupClassName, required, ...rest } = props;
  return (
    <div className={className}>
      <select name={name} id="subject" value={value} className={groupClassName} onChange={onChange} required={required}>
        {options.map((option, index) => (
          <option
            {...rest}
            key={option.value}
            value={option.value}
            selected={option.selected}
            disabled={option.disabled}
            hidden={option.hidden}
            style={option.style}
          >
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default PrimaryDropdown;
