import React from 'react'
import ListYourBusinessMain from '../components/ListYourBusiness'

const ListYourBusiness = () => {

  return (
    <ListYourBusinessMain />
  )

}

export default ListYourBusiness
