import React, { useEffect, useState } from "react";
import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { useRoutes } from "react-router-dom";
import { Router } from "./router/routing";
import { updateToken } from "./redux/actions/authentication/authActions";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import {getMessaging, onMessage} from 'firebase/messaging';

function App() {
  const dispatch = useDispatch();

  const [notification, setNotification] = useState({ title: "", body: "" });

  const token = localStorage.getItem("fs");

  useEffect(() => {
    if (token) {
      dispatch(updateToken(token));
    }
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.pathname]);

  const routing = useRoutes(Router);
  return (
    <>
      <div className="App">
        {routing}
      </div>
    </>
  );
}

export default App;
