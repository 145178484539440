import React from "react";
import ImgContact from "../../assets/images/contact-img.png";
import ContactUsForm from "./ContactUsForm";
import { ToastContainer } from "react-toastify";

const ContactUs = () => {

  return (
    <>
      <ToastContainer />
      <div className="contact-us-mian">
        <div className="container">
          <div className="contact-us-wrapper">
            <div className="row reverse-col">
              <div className="col col-12 col-md-6">
                <div className="main-form">
                  <h4>Contact Us</h4>
                  <p>Want to get in touch? We’d love to hear from you. </p>
                  <ContactUsForm />
                </div>
              </div>
              <div className="col col-12 col-md-6">
                <div className="has-img">
                  <img src={ImgContact} alt="Contact Us" title="Contact Us" width={570} height={463} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
