export const BEARER_TOKEN = "fs";

export const setAuth = (token) => {
  localStorage.setItem(BEARER_TOKEN, token);
};

export const removeAuth = () => {
  localStorage.removeItem(BEARER_TOKEN);
};

export const getAuth = () => {
  return localStorage.getItem(BEARER_TOKEN);
};

export const getAuthToken = () => {
  return localStorage.getItem(BEARER_TOKEN) ? `Bearer ${localStorage.getItem(BEARER_TOKEN)}` : null;
};

export const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};
