import TestimonialItem from "./TestimonialItem";
import { ClientTestimonial1, ClientTestimonial2, ImageTestimonial } from "../../assets/images";
import CustomSlider from "../../common/Slider";

const Testimonial = () => {
  const testimonialSettings = {
    dots: false,
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    className: "slick-col testimonial-arrows",
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="testimonial-section section-gap">
      <div className="container">
        <div className="testimonial-wrapper">
          <div className="row">
            <div className="col col-12 col-md-6 image-block">
              <div className="has-img">
                <img src={ImageTestimonial} alt="Testimonial" title="Testimonial Image" width={597} height={419} />
              </div>
            </div>
            <div className="col col-12 col-md-6 text-left testimonial-inner">
              <h2>Meet Client Satisfaction after working with us</h2>
              <CustomSlider settings={testimonialSettings}>
                <TestimonialItem
                  heading="FlashSearch: A Lifesaver in My Midnight Medical Emergency"
                  testimonial="FlashSearch saved me during a midnight medical emergency by quickly locating a rare medicine. Lifesaving and incredibly efficient!"
                  author="Maulik Shah"
                  avatar={ClientTestimonial1}
                  company="Customer"
                />
                <TestimonialItem
                  heading="Flash Search: Elevating Space 6 Cafe & Banquet to New Heights in Ahmedabad"
                  testimonial="Flash Search has transformed our visibility and reach at Space 6 Cafe & Banquet. It's now easier for patrons to discover our exquisite dining and exceptional event hosting. A true game-changer for our growth!"
                  author="Space 6 Cafe & Banquet"
                  avatar={ClientTestimonial2}
                  company="Cloudexter"
                />
              </CustomSlider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Testimonial;
