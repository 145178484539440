import { Link } from "react-router-dom";
import ImgApp from "../../assets/images/list-business.png";
import ImgFlash from "../../assets/images/f.png";
import ImgPlay from "../../assets/images/play.jpg";
import ImgAppStore from "../../assets/images/app-store.jpg";
const ListYourBusinessMain = () => {
  return (
    <div className="list-your-business-section">
      <div className="container">
        <div className="row">
          <div className="col col-12 col-lg-6 info-block">
            <div className="flash-app-wrapper">
              <div className="app-version">
                <i className="icon">
                  <img src={ImgFlash} alt="Flash" width={64} height={64} />
                </i>
                <h3>
                  Flash Search <span>v1.0</span>{" "}
                </h3>
              </div>
              <h1 className="app-heading">
                Vendor App By <br /> Flash Search
              </h1>
              <div className="app-link">
                <div className="col-6">
                  <Link
                    to="https://apps.apple.com/in/app/flash-search/id6478445975"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={ImgAppStore} alt="ImgAppStore" width={320} height={96} />
                  </Link>
                </div>
                <div className="col-6">
                  <Link
                    to="https://play.google.com/store/apps/details?id=com.app.flashsearch"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={ImgPlay} alt="ImgPlay" width={320} height={96} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-12 col-lg-6 img-block">
            <div className="has-img">
              <img src={ImgApp} alt="Mobile App UI" width={777} height={777} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListYourBusinessMain;
