import React, { useEffect, useRef } from "react";

const LinearProgress = ({ onFinish, progress, setProgress }) => {
  const duration = 60; // Duration in seconds
  const cycleCount = 3;
  const timerRef = useRef(null);
  const cycleRef = useRef(0);

  useEffect(() => {
    function startTimer() {
      timerRef.current = setInterval(() => {
        setProgress((prevProgress) => {
          const newProgress = prevProgress + 100 / duration;
          if (newProgress >= 100) {
            clearInterval(timerRef.current);
            cycleRef.current++;
            if (cycleRef.current < cycleCount) {
              if (progress > 100) {
                setProgress(100);
              }
              // Start the next cycle after 1 second delay
              setTimeout(() => {
                setProgress(0);
                startTimer();
              }, 1000);
            } else {
              // All cycles completed
              onFinish();
            }
          }
          return newProgress;
        });
      }, 1000);
    }

    // Start the initial cycle
    startTimer();

    return () => {
      clearInterval(timerRef.current);
    };
  }, [onFinish]);

  return (
    <div className="progress" style={{ height: "5px", backgroundColor: "#222662" }}>
      <div
        className="progress-bar"
        style={{
          width: `${progress}%`,
          backgroundColor: "#F8A11F", // Filled part color
        }}
        role="progressbar"
        aria-valuenow={progress}
        aria-valuemin="0"
        aria-valuemax="100"
      />
    </div>
  );
};

export default LinearProgress;
