import React from "react";
import Spinner from "../Spinner/Spinner";

const PrimaryButton = (props) => {
  const { children, disabled, loading, ...rest } = props;
  return (
    <>
      <button disabled={disabled || loading} {...rest}>
        {loading ? <Spinner /> : children}
      </button>
    </>
  );
};

export default PrimaryButton;
