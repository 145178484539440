import React from "react";
import Terms from "../components/Terms/Terms";

const TermsMain = () => {
  return (
    <>
      <Terms />
    </>
  );
};

export default TermsMain;
