import { Link } from "react-router-dom";
import { ReactComponent as FooterLogo } from "../../../assets/icons/footer-logo.svg";
import { ReactComponent as IconFB } from "../../../assets/icons/fb.svg";
import { ReactComponent as IconGit } from "../../../assets/icons/git.svg";
import { ReactComponent as IconTwitter } from "../../../assets/icons/twitter.svg";
import { ReactComponent as IconInsta } from "../../../assets/icons/insta.svg";
import { ReactComponent as IconThread } from "../../../assets/icons/thread.svg";
import { ReactComponent as IconLinkedin } from "../../../assets/icons/linkedin.svg";
const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="d-flex footer-top-wrapper">
          <div className="footer-logo">
            <Link to="/">
              <FooterLogo />
            </Link>
          </div>
          <div className="d-flex align-items-center social-wrapper">
            <Link
              to="https://www.instagram.com/flashsearchofficial/#"
              className="icon"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconInsta />
            </Link>
            <Link
              to="https://www.facebook.com/people/Flashsearch/61555783408252/"
              className="icon"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconFB />
            </Link>
            <Link
              to="https://www.linkedin.com/company/flashsearch/"
              className="icon"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconLinkedin />
            </Link>
            <Link
              to="https://www.threads.net/@flashsearchofficial"
              className="icon"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconThread />
            </Link>
          </div>
        </div>
        <div className="footer-navigation">
          <div className="text-left nav-title">
            <h6>Company</h6>
          </div>
          <nav className="d-flex flex-wrap align-items-center">
            <ul className="m-0 p-0 d-flex">
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="/">Press</Link>
              </li>
              <li>
                <Link to="/">Investors</Link>
              </li>
              <li>
                <Link to="/">Events</Link>
              </li>
              <li>
                <Link to="/terms">Terms of use</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy policy</Link>
              </li>
            </ul>
            <div className="button-wrapper">
              <Link to="/contact-us" className="button">
                Contact Us
              </Link>
            </div>
          </nav>
        </div>
        <div className="text-center footer-copyrights">
          <p>© Flasshconnect Technologies LLP 2024- All rights reserved</p>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
