import { Link, useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "../../utilities/generic-helpers/capitalizeLetter";
import { ReactComponent as IconStar } from "../../assets/icons/star.svg";
import { ReactComponent as IconChat } from "../../assets/icons/chat.svg";
import { ReactComponent as IconCall } from "../../assets/icons/call.svg";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import RegisterModal from "../../common/Modal/RegisterModal";
import VerifyModal from "../../common/Modal/VerifyModal";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../redux/actions/authentication/authActions";

import { getCurrentUser } from "../../utilities/auth-helpers/authHelpers";
import { cities } from "../../utilities/generic-helpers/cities";

const RightPanel = ({
  loading,
  vendorData,
  setPage,
  subCategoryID,
  rowsPerPage,
  setRowsPerPage,
  handleCityChange,
  selectedCity,
}) => {
  const userLogiedin = getCurrentUser();
  const navigate = useNavigate();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [showNewModal, setShowNewModal] = useState(false);
  const dispatch = useDispatch();
  const [loggedIn, setLoggedIn] = useState(false);
  const { user } = useSelector((state) => state.authentication);
  const [currentPage, setCurrentPage] = useState(1);
  const handleCloseLogin = () => setShowLoginModal(false);
  const handleCloseNewModal = () => {
    setLoggedIn(false);
    setShowNewModal(false);
  };
  const token = localStorage.getItem("fs");

  const handleShow = () => {
    if (token === null) {
      setShowLoginModal(true);
    }
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.log(error.message);
        }
      );
    } else {
      console.log("Geolocation is not supported by your browser.");
    }
  };

  useEffect(() => {
    getLocation();
  }, []);
  const handleLogin = (formData) => {
    dispatch(userLogin(formData));
  };
  const openVerificationModal = () => {
    if (user?.status === 200) {
      setShowLoginModal(false);
      setShowNewModal(true);
      setLoggedIn(true);
    }
  };

  useEffect(() => {
    openVerificationModal();
  }, [user?.data?.verify_token, user?.status]);

  const renderStars = (rating) => {
    const filledStars = Math.floor(rating); // Get the whole number of filled stars
    const starArray = [];

    for (let i = 0; i < 5; i++) {
      // Create an array of star icons based on the rating
      starArray.push(
        <i key={i} className={`icon ${i < filledStars ? "" : "star-no-fill"}`}>
          <IconStar />
        </i>
      );
    }

    return starArray;
  };

  const handleRowsPerPageChange = (event) => {
    const newPageSize = parseInt(event.target.value);
    setRowsPerPage(newPageSize);
    setPage(1);
    setCurrentPage(1);
  };

  console.log(vendorData, "vendordata");
  return (
    <>
      <div className="right-panel-wrapper">
        <div className="m-b-30 facets-container">
          <div className="d-flex align-items-center sort-on-top">
            {/* <div className="total-results-counter">
            <p>48 Shops Near You</p>
          </div> */}
            <div className="d-flex align-items-center form-dropdown sort-by-dropdown">
              <label htmlFor="nearbyyou">Sort By</label>
              <div className="select-wrapper">
                <select onChange={handleCityChange} value={selectedCity} name="Near By You" id="nearbyyou">
                  <option value="" selected>
                    Near By You
                  </option>
                  {cities.map((city) => (
                    <option value={city}>{city}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="d-flex align-items-center form-dropdown show-dropdown">
              <label htmlFor="show">Show</label>
              <div className="select-wrapper">
                <select onChange={handleRowsPerPageChange} value={rowsPerPage} name="Near By You" id="show">
                  <option value="" disabled selected hidden>
                    12
                  </option>
                  <option value="2">2</option>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="vendor-list-wrapper">
          {vendorData?.data?.length <= 0 && <p>No records found</p>}
          {vendorData?.data &&
            vendorData?.data?.length > 0 &&
            vendorData?.data?.map((item) => (
              <div className="d-flex list-item">
                <div className="image-wrapper">
                  <img src={item?.profile_image} alt="VendorImage" title="Vendor Image" width={251} height={228} />
                  {/* <span className="label-location-distance">Near 3km</span> */}
                </div>
                <div className="text-left vendor-info">
                  <h6 className="m-b-16 vendor-title">{capitalizeFirstLetter(item?.name)}</h6>
                  <div className="d-flex align-items-center m-b-16 rating-block">
                    <span className="m-r-10 rating">{item.rating}</span>
                    <div className="d-flex rating-start">{renderStars(item.rating)}</div>
                    <span className="m-l-10 rating-counter">{item?.total_review} Rating</span>
                  </div>
                  <ul className="m-l-0 p-0 m-b-16 list-style-none d-flex align-items-center vendor-location">
                    <li>{capitalizeFirstLetter(item?.address)}</li>
                  </ul>
                  <div className="d-flex button-wrapper">
                    <div
                      state={{ id: item?.id }}
                      className="d-flex m-r-15 button"
                      onClick={(e) => {
                        if (!userLogiedin) {
                          handleShow();
                          return false;
                        } else {
                          navigate("/messages", { state: { id: item?.id, requestId: 0 } });
                        }
                      }}
                    >
                      <i className="icon">
                        <IconChat />
                      </i>
                      Chat Now
                    </div>
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={<Tooltip id="tooltip-top">{item?.mobile_number}</Tooltip>}
                    >
                      <Button className="d-flex button-secondary">
                        <i className="icon">
                          <IconCall />
                        </i>
                        Call Now
                      </Button>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            ))}
          <div className="pagination-wrapper">
            <ul className="d-flex align-items-center justify-content-center m-b-0 m-l-0 p-l-0 m-t-35">
              {Array.from({ length: vendorData?.totalpage }, (_, index) => index + 1).map((page) => (
                <li
                  key={page}
                  className={`pagination-item ${currentPage === page ? "active" : ""}`}
                  onClick={(e) => {
                    setPage(page);
                    setCurrentPage(page);
                  }}
                >
                  <a>{page}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {showLoginModal && (
        <RegisterModal
          showLoginModal={showLoginModal}
          hide={handleCloseLogin}
          handleLogin={handleLogin}
          latitude={latitude}
          longitude={longitude}
          getLocation={getLocation}
        />
      )}
      {loggedIn && showNewModal && (
        <VerifyModal show={showNewModal} onHide={handleCloseNewModal} status={user?.status} />
      )}
    </>
  );
};
export default RightPanel;
