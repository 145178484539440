let options = [];

export const getOptions = (items) => {
  options = items?.data?.map((item) => {
    return {
      value: item.id,
      actualValue: item.products ? item.products : item.sub_category ? item.sub_category : item.category,
      label: item.products ? `${item.name} (${item.products})` : item.name,
    };
  });
};

const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(undefined);
    }, ms);
  });

const optionsPerPage = 20;

export const loadOptions = async (search, page) => {
  await sleep(1000);

  let filteredOptions;
  if (!search) {
    filteredOptions = options;
  } else {
    const searchLower = search.toLowerCase();

    filteredOptions = options.filter(({ label }) => label.toLowerCase().includes(searchLower));
  }

  const hasMore = Math.ceil(filteredOptions.length / optionsPerPage) > page;
  const slicedOptions = filteredOptions.slice((page - 1) * optionsPerPage, page * optionsPerPage);

  return {
    options: slicedOptions,
    hasMore,
  };
};
