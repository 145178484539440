import React, {useState} from 'react'
import HomeMain from '../components/Home'
import Header from '../components/common/header/header';
import Footer from '../components/common/footer/footer';

const Home = () => {
  const [loginModel,setLoginModel]= useState(false);

  console.log("LoginModel",loginModel)
  return (
    <>
    <Header loginModel={loginModel} setLoginModel={setLoginModel} />
    <HomeMain  setLoginModel={setLoginModel}/>
    <Footer />
    </>
  )

}

export default Home
