import React from "react";

const SecondaryInput = (props) => {
  const { name, value, onChange, placeholder, className, groupClassName, required, ...rest } = props;
  return (
    <div className={className}>
      {/* <label htmlFor={name}>{label}</label> */}
      <textarea
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={groupClassName}
        required={required}
        {...rest}
      />
    </div>
  );
};

export default SecondaryInput;
