import React, { useEffect, useState } from "react";
import { aboutUs } from "../../api/about-us/about";
import { toast, ToastContainer } from "react-toastify";
import Skeleton from "react-loading-skeleton";

const AboutUsMain = () => {
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await aboutUs();
      setContent(response);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="container">
        <div className="m-b-32 text-left heading-wrapper">
          <h2 className="m-b-0 heading">About Us</h2>
        </div>
        {loading ? (
          <Skeleton height={270} />
        ) : (
          <div
            className="content-page"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        )}
      </div>
    </>
  );
};

export default AboutUsMain;
