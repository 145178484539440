import React, { useEffect, useState } from "react";
import BlogHead from "../../assets/images/blog-head.jpg";
import BlogCard from "./BlogCard";
import { fetchBlogs } from "../../redux/actions/blogs/blogsActions";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "../../common/InfiniteScroll/InfiniteScroll";
import BlogLoading from "./BlogLoading";
import LoadingIndicator from "../../common/Spinner/LoadingIndicator";
import NoResults from '../../common/NoResults';

const Blog = () => {
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  const { blogs, has_more, loading } = useSelector((state) => state.blogs);

  const formData = new FormData();
  formData.append("page", page);

  useEffect(() => {
    dispatch(fetchBlogs(formData, page));
  }, [page]);

  return (
    <div className="blog-page">
      <div className="m-b-80 page-heading page-heading--image">
        <div className="bg-image">
          <img src={BlogHead} alt="BlogHead" />
        </div>
        <h2>Blog</h2>
      </div>
      <div className="container">
        <div className="m-b-32 text-left heading-wrapper">
          <h2 className="m-b-0 heading">Latest Blog</h2>
          <p>Your Best Practices Guide for all your local service needs</p>
        </div>
        <div className="blog-items section-gap">
          <InfiniteScroll
            className="blog-item"
            hasMoreData={has_more}
            list={blogs}
            loading={loading}
            loadMore={() => setPage((prevPage) => prevPage + 1)}
            listItemHandler={(blog) => (
              <BlogCard
                image={blog.image}
                title={blog.title}
                author={blog.author}
                description={blog.description}
                status={blog.status}
                id={blog.id}
                width={643}
                height={519}
              />
            )}
            loadingEl={
              <BlogLoading style={{ display: "flex", borderRadius: "25px" }} width={270} height={218} count={4} />
            }
            page={page}
            noResultsEl={<NoResults note="Blogs" />}
          />
          {loading && page > 1 && <LoadingIndicator />}
        </div>
      </div>
    </div>
  );
};

export default Blog;
