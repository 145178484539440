import { contactUsTypes } from "../../constants/contacts/contactUs";

const initialState = {
  contacts: '',
  status: null,
  loading: false,
  error: null,
};

const contactsReducer = (state = initialState, action) => {
  switch (action.type) {
    case contactUsTypes.CONTACTUS_LOADING:
      return {
        ...state,
        loading: true,
        error: null || "",
      };
    case contactUsTypes.CONTACTUS_SUCCESS:
      return {
        ...state,
        contacts: action.payload.message,
        status: action.payload.status,
        loading: false,
        error: null || "",
      };
    case contactUsTypes.CONTACTUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default contactsReducer;
