import React, { useEffect, useState } from "react";
import HeroBannerSlide from "./HeroBannerSlide";
import CustomSlider from "../../common/Slider";
import { useDispatch, useSelector } from "react-redux";
import { bannerService } from "../../redux/actions/banner/bannerActions";
import { ToastContainer, toast } from "react-toastify";
import ProductSearchInput from "./ProductSearchInput";
import { useLoadScript } from "@react-google-maps/api";

const HeroBanner = ({ setLoginModel }) => {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBtBpfN42kD_EyQqlzP47KeWWFrsId83_8",
    libraries: ["places"],
  });

  const { banners } = useSelector((state) => state.banners);
  console.log(banners, "hhh");

  useEffect(() => {
    dispatch(bannerService());
  }, []);

  const heroBannerSettings = {
    dots: true,
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
    className: "hero-banner-arrow",
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  const handleFileSelect = (event) => {
    console.log("event", event);
    const file = event?.target.files[0];

    if (file) {
      // Check if the selected file is an image (image/* mime type)
      if (!file.type.startsWith("image/")) {
        toast.error("Please select an image file.");
        return;
      }

      resizeImageTo200x200(file, (resizedFile) => {
        setSelectedImage(resizedFile);
      });
    }
  };
  const resizeImageTo200x200 = (imageFile, callback) => {
    const MAX_WIDTH = 200;
    const MAX_HEIGHT = 200;

    const img = new Image();
    img.src = URL.createObjectURL(imageFile);

    img.onload = () => {
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }

      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob((blob) => {
        const resizedFile = new File([blob], imageFile.name, { type: "image/jpeg" });
        callback(resizedFile);
      }, "image/jpeg");
    };
  };
  return (
    <>
      <ToastContainer />
      <div className="hero-banner-section">
        <CustomSlider settings={heroBannerSettings}>
          {banners?.data?.map((banner) => (
            <HeroBannerSlide
              key={banner?.id}
              src={banner?.image}
              alt="BannerImage"
              title={banner?.name}
              width={1920}
              height={600}
            />
          ))}
        </CustomSlider>
        <div className="container">
          <div className="hero-banner-wrapper">
            <h2 className="m-b-40 text-center">Find Anything, Anytime, Anywhere.</h2>
            <ProductSearchInput
              isLoaded={isLoaded}
              handleFileSelect={handleFileSelect}
              selected={selected}
              setSelected={setSelected}
              selectedImage={selectedImage}
              setLoginModel={setLoginModel}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroBanner;
