import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import NotificationCard from "../components/Notifications/NotificationCard";
import InfiniteScroll from "../common/InfiniteScroll/InfiniteScroll";
import NoResults from "../common/NoResults";
import { getnotificationList } from "../api/notifications/notifications";
import NotificationLoading from "../components/Notifications/NotificationLoading";

const Notifications = () => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [notificationList, setNotificationList] = useState({
    items: [],
    has_more: false,
  });

  const getNotifications = async () => {
    setLoading(true);
    try {
      const response = await getnotificationList(page);
      setNotificationList((prevList) => ({
        items: [...prevList.items, ...response?.data?.items],
        has_more: response?.data?.has_more,
      }));
      setLoading(false);
    } catch (error) {
      if(error?.status === 401){
        localStorage.clear()
        window.location.replace('/')
      }
      setLoading(false);
      console.log(error?.message);
    }
  };

  useEffect(() => {
    getNotifications();
  }, [page]);

  return (
    <>
      <div className="container">
        <div className="m-b-32 text-left heading-wrapper">
          <h2 className="m-b-0 heading">Notifications</h2>
        </div>
      </div>
      <Container>
        <Row className="justify-content-center mt-4">
          <Col xs={12} md={8}>
            <div className="notification-list">
              <InfiniteScroll
                hasMoreData={notificationList?.has_more}
                list={notificationList?.items}
                loading={loading}
                loadMore={() => setPage((prevPage) => prevPage + 1)}
                listItemHandler={(notification) => (
                  <NotificationCard
                    key={notification.id}
                    date={notification.created_at}
                    title={notification.push_title}
                    message={notification.push_message}
                  />
                )}
                loadingEl={
                  <NotificationLoading
                    style={{ display: "flex", borderRadius: "25px" }}
                    width={1000}
                    height={100}
                    count={4}
                  />
                }
                noResultsEl={<NoResults note="Notifications" />}
                page={page}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Notifications;
