import { fetchBanners as fetchBannersServices} from "../../../api/banner/banner";
import { bannerTypes } from "../../constants/banner/bannerTypes";

export const bannerService = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: bannerTypes.FETCH_BANNER_MAIN_LOADING,
      });

      const banner = await fetchBannersServices();
      dispatch({
        type: bannerTypes.FETCH_BANNER_MAIN_SUCCESS,
        payload: banner,
      });
    } catch (error) {
      dispatch({
        type: bannerTypes.FETCH_BANNER_MAIN_FAILURE,
        payload: error.message,
      });
    }
  };
};
