import Blog from './blog'
import HeroBanner from './HeroBanner'
import ProgressIndicator from './progress-indicator'
import SponsoredAd from './sponsored-ad'
import Testimonial from './Testimonial'
import CategoryPanel from '../category-panel/CategoryPanel'

const HomeMain = ({setLoginModel}) => {
  // console.log("LoginModel",setLoginModel)

  return (
    <>
      <HeroBanner  setLoginModel={setLoginModel}/>
      <ProgressIndicator />
      <CategoryPanel />
      <SponsoredAd />
      <Blog />
      <Testimonial />
    </>
  )
}

export default HomeMain
