import React from 'react'
import ListOfVendorMain from '../components/ListOfVendor'

const ListOfVendor = () => {

  return (
    <ListOfVendorMain />
  )

}

export default ListOfVendor
