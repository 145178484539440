import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BlogCard from "../Blog/BlogCard";
import BlogLoading from "../Blog/BlogLoading";
import { useEffect } from "react";
import { fetchBlogs } from "../../redux/actions/blogs/blogsActions";
const Blog = () => {
  const dispatch = useDispatch();

  const { blogs, loading } = useSelector((state) => state.blogs);

  let page = 1;

  const formData = new FormData();
  formData.append("page", page);

  useEffect(() => {
    dispatch(fetchBlogs(formData, page));
  }, [page]);

  const fiveBlogs = blogs?.slice(1, 5);

  return (
    <div className="blog-section with-bg section-gap">
      <div className="container">
        <div className="blog-wrapper">
          <div className="d-flex flex-wrap align-items-center m-b-32">
            <div className="text-left heading-wrapper">
              <h2 className="m-b-0 heading">WE'VE GOT IT BLOGGED</h2>
              <p>Your Best Practices Guide for all your local service needs</p>
            </div>
            <div className="m-l-auto action-block">
              <Link to="/blog" className="button-secondary">
                View All
              </Link>
            </div>
          </div>

          {!blogs.length && <div style={{ display: "flex", justifyContent: "center" }}>No Blogs Found</div>}
          <div className="blog-items-list grid">

            <div className={`blog-item grid-item1`}>
              {loading && (
                <BlogLoading count={1} width={643} height={519} style={{ borderRadius: "35px", display: "flex" }} />
              )}
              {!loading && blogs?.length > 0 && (
                <BlogCard
                  image={blogs[0].image}
                  title={blogs[0].title}
                  author={blogs[0].author}
                  description={blogs[0].description}
                  status={blogs[0].status}
                  id={blogs[0].id}
                  width={643}
                  height={519}
                />
              )}
            </div>
            {fiveBlogs?.length > 0 &&
              fiveBlogs?.map((blog, index) => (
                <>
                  {loading ? (
                    <div className={`blog-item grid-item${index + 2}`}>
                      <>
                        <BlogLoading
                          count={4}
                          width={270}
                          height={218}
                          style={{ borderRadius: "25px", display: "flex" }}
                        />
                      </>
                    </div>
                  ) : (
                    <div className={`blog-item grid-item${index + 2}`}>
                      <>
                        <BlogCard
                          image={blog.image}
                          title={blog.title}
                          author={blog.author}
                          description={blog.description}
                          status={blog.status}
                          id={blog.id}
                          width={270}
                          height={218}
                          created_at={blog?.created_at}
                        />
                      </>
                    </div>
                  )}
                </>
              ))}

            {/* {loading ? <BlogLoading count={4} width={270} height={218} style={{ borderRadius: "35px", display: "flex" }}/> : !loading &&
              fiveBlogs?.length > 0 &&
              fiveBlogs?.map((blog, index) => (

              ))} */}

            {/* <div className='m-b-10 blog-image'>
                <img src={ImgAd} alt='Cloud Computing a Revolution in the Digit...' title='Cloud Computing a Revolution in the Digit...' width={643} height={519} />
              </div>
              <p className='text-left m-t-16 m-b-10 blog-tagline'>Adhithiya Rajasekar - June 20, 2022</p>
              <h4 className='text-left sm-b-10 blogs-title'>
                <Link to='/' className='m-b-20'>Cloud Computing a Revolution in the Digit...</Link>
              </h4>
              <p className='text-left blog-des'>In this digital era, businesses are moving towards better options for growth and success. Cloud computing is a smart...</p> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Blog;
