  import React from "react";
  import { Link } from "react-router-dom";
  import moment from 'moment';

  const BlogCard = (props) => {
    const { image, title, author, id, width, height , created_at } = props;

    return (
      <>
        <Link to={`/blog/${id}`} className="m-b-20">
          <div className="m-b-10 blog-image">
            <img
              src={image}
              alt="Cloud Computing a Revolution in the Digit..."
              title={title}
              width={width}
              height={height}
            />
          </div>
          <p className="text-left m-t-16 m-b-10 blog-tagline">{author} - {created_at && moment(created_at).format("MMMM DD, YYYY")}</p>
          <h4 className="text-left sm-b-10 blogs-title">{title}</h4>
          <p className="text-left blog-des">
            In this digital era, businesses are moving towards better options for growth and success. Cloud computing is a
            smart...
          </p>
        </Link>
      </>
    );
  };

  export default BlogCard;
