import React from "react";

const PrimaryInput = (props) => {
  const { name, type, value, onChange, placeholder, className, groupClassName, required } = props;
  return (
    <div className={className}>
      {/* <label htmlFor={name}>{label}</label> */}
      <input
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={groupClassName}
        required={required}
      />
    </div>
  );
};

export default PrimaryInput;
