import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import { terms } from "../../api/terms/terms";

const Terms = () => {
  const [content, setContent] = useState();
  const [loading, setLoading] = useState();

  const data = async () => {
    setLoading(true);
    try {
      const response = await terms();
      setLoading(false);
      setContent(response);
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  useEffect(() => {
    data();
  }, []);

  return (
    <>
      <div className="container">
        <div className="m-b-32 text-left heading-wrapper">
          <h2 className="m-b-0 heading">Terms & Conditions</h2>
        </div>
        <p dangerouslySetInnerHTML={{ __html: content }}></p>
      </div>
    </>
  );
};

export default Terms;
