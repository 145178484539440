import React from 'react'
import {FadeLoader} from 'react-spinners'

const LoadingIndicator = () => {
  return (
    <div style={{display:"flex", justifyContent:"center"}}>
    <FadeLoader color="#F8A11F" />
    </div>
  )
}

export default LoadingIndicator
