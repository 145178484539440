import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IconCamera, IconLocation, IconSearch } from "../../assets/icons";
import PlacesAutoComplete from "../../common/AutoComplete";
import { Link, useNavigate } from "react-router-dom";
import { searchProduct, searchVendor } from "../../api/products/products";
import SearchProductModal from "../../common/Modal/SearchProductModal";
import { getCurrentUser } from "../../utilities/auth-helpers/authHelpers";
import { toast, ToastContainer } from "react-toastify";
import AccessModal from "../../common/Modal/AccessModal";
import { getFirebaseToken } from "../../config/firebase";
import { apiClient } from "../../api/client";
import { getOptions, loadOptions } from "../../utilities/generic-helpers/loadOptions";
import { AsyncPaginate } from "react-select-async-paginate";
import { getMessaging, onMessage } from "firebase/messaging";

const ProductSearchInput = ({ handleFileSelect, selected, setSelected, selectedImage, isLoaded, setLoginModel }) => {
  const navigate = useNavigate();

  const [loading,setLoading] = useState(true);
  const [productload,setProductload] = useState(false);
  const [notificationShow, setNotificationShow] = useState(false);
  const [show, setShow] = useState(false);
  const [progressBar, setProgressBar] = useState(0);
  const [vendorResponse, setVendorResponse] = useState({});
  const [value, onChange] = useState(null);
  const [inputValues, onInputChangeRaw] = useState("");

  const onInputChange = useCallback((newInputValue, { action }) => {
    onInputChangeRaw(newInputValue);
  }, []);

  // useEffect(() => {
  //   if (token && Notification.permission === "granted") {
  //     console.log("granted");
  //     handleGetFirebaseToken();
  //   } else if (token && Notification.permission === "denied") {
  //     console.log("denied");
  //     toast.error("Please enable push notification for better experience");
  //   }
  // }, [token]);

  const OpenNotifyModal = () => {
    if (Notification.permission !== "granted") {
      setNotificationShow(true);
    } else {
      setNotificationShow(false);
    }
  };

  const CloseNotifyModal = () => {
    setNotificationShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setVendorResponse({});
    setProgressBar(0);
    setShow(false);
  };

  const latitude = selected?.lat || null;
  const longitude = selected?.lng || null;

  const submittable = latitude && longitude && (value || inputValues);

  const productListing = async () => {
    try {
      setLoading(true)
      setProductload(true)
      const formData = new FormData();
      formData.append("product", inputValues);
      formData.append("page", 1);
      const response = await searchProduct(formData);
      const products = response;
      return getOptions(products);
    } catch (error) {
      console.log(error);
    }
    finally{
      setLoading(false)
      setTimeout(() =>{
        setProductload(false)
      },2000)
    }
  };

  useEffect(() => {
    if (inputValues.length > 2) {
      productListing();
    }
  }, [inputValues]);

  // Manage Foreground Message

  useEffect(() => {
    const messaging = getMessaging();

    const unsubscribe = onMessage(messaging, (payload) => {
      console.log("Message received. ", payload);
      navigate("/messages", {
        state: {
          id: payload?.data?.from_user_id,
          searchFor: value?.label || inputValues,
          file: selectedImage,
          requestId: payload?.data?.object_id,
          location: payload?.data?.location,
        },
      });
    });

    return () => {
      unsubscribe(); // This is important to unsubscribe when the component unmounts
    };
  }, []);

  const handleVendorSearch = async () => {
    console.log(selectedImage, "selectedImage");
    if (Notification.permission !== "granted") return;
    if (vendorResponse?.data?.request_count >= 4) return;
    const formData = new FormData();
    if (value) {
      console.log(value, "value----");
      formData.append("product", value?.actualValue);
    } else {
      formData.append("product", inputValues);
    }
    if (selectedImage) {
      formData.append("product_image", selectedImage);
    }
    formData.append("location", selected.address);
    formData.append("lat", latitude);
    formData.append("long", longitude);
    if (vendorResponse?.data?.id && vendorResponse?.data?.request_count <= 3) {
      formData.append("request_id", vendorResponse?.data?.id);
    }
    try {
      setProgressBar(0);
      const response = await searchVendor(formData);
      if (response?.data?.accepted_vendor_id != null) {
        navigate("/messages", {
          state: {
            id: response?.data?.accepted_vendor_id,
            searchFor: value.label || inputValues,
            file: selectedImage,
            requestId: response?.data?.id,
            location:response?.data?.location
          },
        });
      }
      setVendorResponse(response);
    } catch (error) {
      if (error?.status === 401) {
        handleClose();
        setLoginModel(true);
        window.location.reload();
        // dispatch(updateToken(token));
        localStorage.clear();
      }
    }
  };

  useEffect(() => {
    if (Math.floor(progressBar) === 100) {
      if (vendorResponse?.data?.id && vendorResponse?.data?.request_count < 3) {
        handleVendorSearch();
      }
      if (vendorResponse?.data?.id && vendorResponse?.data?.request_count >= 3) {
        toast.error("Vendor not found for this product");
        setTimeout(() => {
          setVendorResponse({});
          setProgressBar(0);
          handleClose();
        }, 2000);
      }
    }
  }, [progressBar, vendorResponse?.data?.request_count]);

  const saveFCMToken = async (token) => {
    const formData = new FormData();
    formData.append("fcm_token", token);
    try {
      await apiClient.post("/user/fcm_token_update", formData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetFirebaseToken = () => {
    CloseNotifyModal();
    getFirebaseToken()
      .then((firebaseToken) => {
        console.log("Firebase token: ", firebaseToken);
        if (firebaseToken) {
          saveFCMToken(firebaseToken);
          // setShowNotificationBanner(false);
        }
      })
      .catch((err) => console.error(err));
  };

  const defaultAdditional = {
    page: 1,
  };

  const loadPageOptions = async (q, prevOptions, { page }) => {
    const { options, hasMore } = await loadOptions(q, page , setLoading);
    return {
      options,
      hasMore,

      additional: {
        page: page + 1,
      },
    };
  };

  const requestNotificationPermission = () => {
    if (Notification.permission === "default") {
      Notification.requestPermission().then((permission) => {
        console.log(permission, "permission");
        if (permission === "granted") {
          CloseNotifyModal();
          handleGetFirebaseToken();
          // Permission granted, handle your notification logic here
          // e.g., call handleGetFirebaseToken()
        } else if (permission === "denied") {
          // Permission denied by the user
        } else if (permission === "dismissed") {
          // Permission request was dismissed by the user
          toast.error("Notification permission request was dismissed.");
        }
      });
    }
  };

  const onFocus = () => setIsFocused(true);
  const onBlur = () => setIsFocused(false);
  const [isFocused, setIsFocused] = useState(false);
  const placeholderText = isFocused
    ? "Product must be at least 3 characters"
    : "Search for a product...";
  return (
    <>
      <ToastContainer />
      <div className="d-flex flex-wrap align-items-center search-block-wrapper">
        <div className="search-input d-flex align-items-center">
          <Link to="" className="ml-3 icon--search">
            <IconSearch />
          </Link>
          <div className="search-box" style={{width:"300px"}}>
            <AsyncPaginate
              additional={defaultAdditional}
              inputValue={inputValues}
              onInputChange={onInputChange}
              onChange={onChange}
              value={value}
              loadOptions={!loading && loadPageOptions}
              placeholder={placeholderText}
              onFocus={onFocus}
              onBlur={onBlur}
              isLoading={productload}
            />
          </div>

          <label class="form-label icon icon-camera mb-0" for="avatar">
            {selectedImage ? (
              <img
                src={URL.createObjectURL(selectedImage)}
                alt="SelectedImage"
                style={{ height: "30px", maxWidth: "30px" }}
              />
            ) : (
              <IconCamera />
            )}
          </label>
          <input
            type="file"
            accept="image/jpeg, image/png"
            onInput={handleFileSelect}
            id="avatar"
            style={{ display: "none" }}
          />
        </div>
        <Link to="" className="icon icon-location">
          <IconLocation />
        </Link>
        <div className="places-container">
          <PlacesAutoComplete selected={selected} setSelected={setSelected} isLoaded={isLoaded} />
        </div>
        <button
          onClick={() => {
            const user = getCurrentUser();
            if (!user) {
              // Component that updates localStorage
              setLoginModel(true);
              // alert("please login")
              return false;
            }
            // if (Notification.permission !== "granted") {
            //   // Component that updates localStorage
            //   requestNotificationPermission()
            //   OpenNotifyModal();
            //   // alert("please login")
            //   return false;
            // }else

            if (Notification.permission === "denied") {
              // Permission denied by the user
              requestNotificationPermission();
              OpenNotifyModal();
              return false;
            } else if (Notification.permission === "dismissed" || Notification.permission === "default") {
              // Permission request was dismissed by the user
              toast.error("Notification permission request was dismissed.");
              requestNotificationPermission();
              OpenNotifyModal();
              return false;
            }
            setShow(true);
            handleVendorSearch();
          }}
          type="button"
          className="button"
          disabled={!submittable ? true : false}
        >
          Search Vendor
        </button>
        {notificationShow && (
          <AccessModal
            notificationPopUp={handleGetFirebaseToken}
            showNotificationModal={OpenNotifyModal}
            hide={CloseNotifyModal}
          />
        )}
        {show && (
          <SearchProductModal
            selectedOption={value || inputValues}
            show={handleShow}
            hide={handleClose}
            selected={selected}
            selectedImage={selectedImage}
            setProgress={setProgressBar}
            progress={progressBar}
            lat={latitude}
            long={longitude}
            vendorResponse={vendorResponse}
          />
        )}
      </div>
    </>
  );
};

export default ProductSearchInput;
