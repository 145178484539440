import React, { useCallback, useMemo, useRef } from "react";
import Skeleton from "react-loading-skeleton";

const InfiniteScroll = (props) => {
  const { hasMoreData, list, loading, loadMore, listItemHandler, loadingEl, page, noResultsEl, className } = props;


  const hasMore = useMemo(() => hasMoreData, [hasMoreData]);

  const observer = useRef();

  const lastListElementRef = useCallback((node) => {
    if (loading) return;

    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore) loadMore();
      },
      { threshold: 1 }
    );
    if (node) observer.current.observe(node);
  });

  return (
    <>
      {list?.length > 0
        ? list.map((listItem, i) => {
            const isLast = list.length === i + 1;

            const el = listItemHandler(listItem);

            return isLast ? (
              <div className={className ? className : ""} key={listItem?.id} ref={lastListElementRef}>
                {el}
              </div>
            ) : (
              <div className={className ? className : ""} key={listItem?.id}>
                {el}
              </div>
            );
          })
        : !loading && (noResultsEl || <></>)}
      {!list.length && loading && page === 1 && (loadingEl || <Skeleton className="mb-3" height={50} />)}
    </>
  );
};

export default InfiniteScroll;
