import { combineReducers } from "redux";
import blogReducer from "./blogs/blogsReducer";
import userReducer from "./authentication/authReducer";
import sponsoredAdReducer from "./sponsoredAd/sponsoredAdReducer";
import categoriesReducer from "./categories/categoriesReducer";
import productsReducer from "./products/productsReducer";
import contactsReducer from "./contacts/contactsReducer";
import bannerReducer from "./banner/bannerReducer";

export default combineReducers({
  authentication: userReducer,
  blogs: blogReducer,
  banners: bannerReducer,
  sponsoredAd: sponsoredAdReducer,
  categories: categoriesReducer,
  products: productsReducer,
  contacts: contactsReducer,
});
