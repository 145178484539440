import { initializeApp } from "firebase/app";
import { getToken, getMessaging, onMessage } from "firebase/messaging";

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MSG_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
// };

const firebaseConfig = {
  apiKey: "AIzaSyDljegAvGLcRIEpHoqHg6vbSfPHzaUx9m0",
  authDomain: "flash-search-376012.firebaseapp.com",
  projectId: "flash-search-376012",
  storageBucket: "flash-search-376012.appspot.com",
  messagingSenderId: "773521542882",
  appId: "1:773521542882:web:ff8f13f49cc5c51f7058bb",
  measurementId: "G-SZC2YPG0YF",
};

// console.log('*** Environment ***', process.env.REACT_APP_ENV)
console.log("*** Firebase Config ***", firebaseConfig);

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getOrRegisterServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    return window.navigator.serviceWorker.getRegistration("/firebase-push-notification-scope").then((serviceWorker) => {
      if (serviceWorker) return serviceWorker;
      return window.navigator.serviceWorker.register("/firebase-messaging-sw.js", {
        scope: "/firebase-push-notification-scope",
      });
    });
  }
  throw new Error("The browser doesn`t support service worker.");
};

export const getFirebaseToken = () =>
  getOrRegisterServiceWorker().then((serviceWorkerRegistration) =>
    getToken(messaging, {
      vapidKey: "BH_biy3qRhdxpDoZOwgbw4oxDz5QUngdyrHBWo9Umupjx-VQlNy4BmRmbCFRkz3jfKiy0SDPModd2K6GHapFGhg",
      serviceWorkerRegistration,
    })
  );

export const onForegroundMessage = () => {
  onMessage((payload) => {
    console.log("Message received. ", payload);
    // Customize notification here
    const notificationTitle = payload.data.title;
    const notificationOptions = {
      body: payload.data.body,
      icon: payload.data.icon || "/logo192.png", // your app icon
    };

    if (Notification.permission === "granted") {
      new Notification(notificationTitle, notificationOptions);
    }
  });
};

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       console.log("payload", payload);
//       resolve(payload);
//     });
//   });
