import React from "react";

const NoResults = ({ note }) => {
  return (
    <div className="text-center mt-4">
      <p>{`No ${note} Found`}</p>
    </div>
  );
};

export default NoResults;
