export const cities = [
  "Ahmedabad",
  "Amreli",
  "Anand",
  "Bharuch",
  "Bhavnagar",
  "Bhuj",
  "Botad",
  "Dahod",
  "Deesa",
  "Gandhidham",
  "Gandhinagar",
  "Godhra",
  "Gondal",
  "Jamnagar",
  "Jetpur",
  "Junagadh",
  "Kalol",
  "Mehsana",
  "Morbi",
  "Nadiad",
  "Navsari",
  "Palanpur",
  "Patan",
  "Porbandar",
  "Rajkot",
  "Surat",
  "Surendranagar",
  "Vadodara",
  "Valsad",
  "Vapi",
  "Veraval",
];
