import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import CloseButton from "../Button/CloseButton";
import { useDispatch } from "react-redux";
import PrimaryButton from "../Button/PrimaryButton";
import { userData } from "../../redux/actions/authentication/authActions";
import { toast } from "react-toastify";
import { getFirebaseToken } from "../../config/firebase";
import * as Yup from 'yup'
import { Formik, Form, ErrorMessage, Field } from "formik";

const RegisterModal = ({
  showLoginModal,
  hide,
  handleLogin,
  latitude,
  longitude,
  setLatitude,
  setLongitude,
  getLocation,
}) => {
  const initialValues = {
    name: "",
    email: "",
    mobileNumber: "",
  };
  const [locationError, setLocationError] = useState(false);
  const [showNotificationBanner, setShowNotificationBanner] = useState(Notification.permission === "default");
  const [fcm, setFcm] = useState("");
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const onSubmit = (values, { resetForm }) => {
    if (latitude === null && longitude === null) {
      setLocationError(true);
      return;
    }
    setIsSubmittingForm(true);
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("email", values.email);
    formData.append("country_code", "+91");
    formData.append("mobile_number", values.phoneNumber);
    formData.append("longitude", longitude);
    formData.append("latitude", latitude);
    formData.append("fcm_token", fcm);
    formData.append("device_type", "web");
    try {
      handleLogin(formData);
      dispatch(
        userData({
          name: values.name,
          email: values.email,
          country_code: "+91",
          mobile_number: values.phoneNumber,
          latitude: latitude,
          longitude: longitude,
          fcm_token: fcm,
          device_type: "web",
        })
      );
    } catch (error) {
      // toast.error(error);
    }
    setTimeout(() => {
      setIsSubmittingForm(false);
      console.log("Form data submitted:", values);
      resetForm();
    }, 1000);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    phoneNumber: Yup.string().required("Phone number is required"),
  });

  const dispatch = useDispatch();

  // useEffect(() => {
  //   onForegroundMessage()
  //     .then((payload) => {
  //       console.log("Received foreground message: ", payload);
  //       const {
  //         notification: { title, body },
  //       } = payload;
  //       toast(<ToastifyNotification title={title} body={body} />);
  //     })
  //     .catch((err) => console.log("An error occured while retrieving foreground message. ", err));
  // }, []);

  const handleGetFirebaseToken = () => {
    getFirebaseToken()
      .then((firebaseToken) => {
        setFcm(firebaseToken);
        localStorage.setItem("fcm", firebaseToken);
        console.log("Firebase token: ", firebaseToken);
        if (firebaseToken) {
          setShowNotificationBanner(false);
        }
      })
      .catch((err) => console.error("An error occured while retrieving firebase token. ", err));
  };

  useEffect(() => {
    if (Notification.permission === "granted") {
      handleGetFirebaseToken();
    }
  }, []);

  // const ToastifyNotification = ({ title, body }) => (
  //   <div className="push-notification">
  //     <h2 className="push-notification-title">{title}</h2>
  //     <p className="push-notification-text">{body}</p>
  //   </div>
  // );

  // const fcmToken = localStorage.getItem('fcm')

  return (
    <>
      <Modal show={showLoginModal} onHide={hide}>
        <div className="modal-content">
          {showNotificationBanner && (
            <div className="notification-banner">
              <span>The app needs permission to&nbsp;</span>
              <a href="#" className="notification-banner-link" onClick={handleGetFirebaseToken}>
                enable push notifications.
              </a>
            </div>
          )}
          <Modal.Header>
            <Modal.Title>
              <div className="modal-header">
                <h4 className="modal-title" id="exampleModalLabel">
                  Find this from and Get Quotes from Best AC Service in Ahmedabad
                </h4>
                <CloseButton handleClose={hide} />
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
              {({ isSubmitting, isValid }) => (
                <Form>
                  <div>
                    <div className="mb-3">
                      <Field type="text" id="name" name="name" className="form-control" placeholder="Enter your name *" />
                      <ErrorMessage name="name" component="div" className="text-danger" />
                    </div>
                    <div className="mb-3">
                      <Field
                        type="text"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Enter your email (optional)"
                      />
                    </div>
                    <div className="mb-3">
                      <Field
                        type="text"
                        id="phoneNumber"
                        name="phoneNumber"
                        className="form-control"
                        placeholder="Enter your mobile number *"
                        maxLength={10}
                      />
                      <ErrorMessage name="phoneNumber" component="div" className="text-danger" />
                    </div>
                  </div>
                  <PrimaryButton type="submit" disabled={isSubmitting || !isValid || locationError} className="button">
                    {isSubmittingForm ? "Submitting..." : "Submit"}
                  </PrimaryButton>
                  <div
                    className="modal-footer"
                    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                  >
                    <>
                      <div>
                        {locationError && (
                          <p className="text-danger">Please grant access to your location to proceed.</p>
                        )}
                      </div>
                    </>
                  </div>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default RegisterModal;
