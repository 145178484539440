import React from "react";
import { ImgUser } from "../../../assets/images";
import { useSelector } from "react-redux";
import {getCurrentUser} from '../../../utilities/auth-helpers/authHelpers';

const LoginSection = ({  handleShow }) => {
  const token = useSelector((state) => state.authentication.token);
  const userLogiedin = getCurrentUser();


  return (
    <div className="user-login">
      <button
      onClick={handleShow}
        // to={`${token === null ? "#exampleModal" : "#logoutModal"}`}
        type="button"
        // data-bs-toggle="modal"
        // data-bs-target={`${token === null ? "#exampleModal" : "#logoutModal"}`}
        className="d-flex align-items-center small-font"
      >
        {`${token === null ? "Login" : "Logout"}`}&nbsp;&nbsp;&nbsp;
        {userLogiedin && userLogiedin?.name && userLogiedin?.name}
        <i className="m-l-6 icon">
          <img src={ImgUser} alt="Login" title="Login" width={35} height={35} />
        </i>
      </button>
    </div>
  );
};

export default LoginSection;
