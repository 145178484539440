import { apiClient } from "../client";
import handleError from "../../utilities/api-helpers/handleError";
import tcWrap from "../../utilities/generic-helpers/tcWrap";

export const fetchProducts = (payload) =>
  tcWrap(async () => {
    const data = await apiClient.post("/general/product_listing", payload).catch(handleError);
    console.log(data,"from profudt")
    return data;
  });

export const searchVendor = (payload) =>
  tcWrap(async () => {
    const data = await apiClient.post("/user/vendorSearch", payload).catch(handleError);
    return data;
  });

  export const searchProduct = (payload) =>
  tcWrap(async () => {
    const data = await apiClient.post("/user/product-search", payload).catch(handleError);
    return data;
  });
