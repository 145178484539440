import { fetchAds as adsService } from "../../../api/sponsoredAd/sponsoredAd";
import { sponsoredAdTypes } from "../../constants/sponsoredAd/sponsoredAdTypes";

export const adsServices = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: sponsoredAdTypes.FETCH_BANNER_LOADING,
      });

      const banner = await adsService();
      dispatch({
        type: sponsoredAdTypes.FETCH_BANNER_SUCCESS,
        payload: banner,
      });
    } catch (error) {
      dispatch({
        type: sponsoredAdTypes.FETCH_BANNER_FAILURE,
        payload: error.message,
      });
    }
  };
};
