export const navLinks = [
  {
    id: 0,
    title: 'Home',
    link: '/',
    className: 'nav-item',
  },
  {
    id: 1,
    title: 'Blog',
    link: '/blog',
    className: 'nav-item',
  },
  {
    id: 2,
    title: 'About Us',
    link: '/about-us',
    className: 'nav-item',
  },
  {
    id: 3,
    title: 'Contact Us',
    link: '/contact-us',
    className: 'nav-item',
  },
  {
    id: 4,
    title: 'List Your Business',
    link: '/list-your-business',
    className: 'nav-item',
  },
  {
    id: 4,
    title: 'Login',
    link: '/login',
    className: 'show-for-mobile user-login nav-item',
  },
]
