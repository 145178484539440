import axios from "axios";
import { REACT_PUBLIC_API_URL } from "../constants/env";
import { BEARER_TOKEN } from "../utilities/auth-helpers/authHelpers";

export const apiClient = (() => {
  const api = axios.create({
    baseURL: `${REACT_PUBLIC_API_URL}/api/V1`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  api.interceptors.request.use(
    (config) => {
      let token = localStorage.getItem(BEARER_TOKEN);
      if (token) {
        config.headers.Authorization = `Bearer ${localStorage.getItem(BEARER_TOKEN)}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  api.interceptors.response.use(
    (response) => response.data || response,
    (error) => {
      // const originalRequest = error.config;

      if (error.response) {
        switch (error.response.status) {
          case 500:
            if (error) {
              console.log(error);
            }
            // else if (originalRequest?.onServiceUnavailable) {
            //   // return originalRequest.onServiceUnavailable(error?.response?.data || error?.response)
            // }
            else {
              return Promise.reject(error.response.data);
            }
            break;
          default:
            return Promise.reject(error.response.data);
        }
      }
    }
  );

  return api;
})();
