import { useLocation } from "react-router-dom";
import LeftPanel from "./left-panel";
import RightPanel from "./right-panel";
import tcWrap from "../../utilities/generic-helpers/tcWrap";
import { apiClient } from "../../api/client";
import handleError from "../../utilities/api-helpers/handleError";
import { useEffect, useState } from "react";

const ListOfVendorMain = () => {
  const [loading, setLoading] = useState();
  const [vendorData, setVendorData] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [selectedCity, setSelectedCity] = useState("");
  const location = useLocation();
  const subCategoryID = location?.state?.id;

  const getVendorList = (payload) =>
    tcWrap(async () => {
      const data = await apiClient.post("/venodr-list", payload).catch(handleError);
      return data;
    });

  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);
  };

  useEffect(() => {
    const vendorListData = async () => {
      setLoading(true);
      const formData = new FormData();
      // formData.append("sub_category_id", 15)
      formData.append("sub_category_id", subCategoryID);
      formData.append("page", page);
      formData.append("per_page", rowsPerPage);
      formData.append("city", selectedCity);
      try {
        const response = await getVendorList(formData);
        setVendorData(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    vendorListData();
  }, [subCategoryID, page, rowsPerPage, selectedCity]);

  return (
    <div className="list-of-vendor-section m-t-80 section-gap">
      <div className="container">
        <div className="row">
          {/* <div className='col col-12 col-lg-4 col-xl-3'>
            <LeftPanel />
          </div> */}
          <div className="col col-12 col-lg-8 col-xl-9">
            <RightPanel
              setRowsPerPage={setRowsPerPage}
              rowsPerPage={rowsPerPage}
              loading={loading}
              vendorData={vendorData}
              setPage={setPage}
              subCategoryID={subCategoryID}
              handleCityChange={handleCityChange}
              selectedCity={selectedCity}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListOfVendorMain;
