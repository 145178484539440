import React from 'react'
import ContactUsMain from '../components/ContactUs'

const ContactUs = () => {

  return (
    <ContactUsMain />
  )

}

export default ContactUs
