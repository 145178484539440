import React, { useState, useRef } from "react";

const OTPInput = ({ length = 6, setOTP, otp }) => {
  const inputsRef = useRef([]);

  const handleInputChange = (index, event) => {
    const value = event.target.value;
    if (!isNaN(value)) {
      setOTP((prevOTP) => {
        const newOTP = [...prevOTP];
        newOTP[index] = value;
        console.log(newOTP.join().replace(/,/g, ''), '000')
        return newOTP;
      });

      if (index < length - 1 && value !== "") {
        inputsRef.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && index > 0 && otp[index] === "") {
      inputsRef.current[index - 1].focus();
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData("text/plain").slice(0, length);
    setOTP(pasteData.split(""));
  };

  return (
    <>
      {otp.map((digit, index) => (
        <input
          key={index}
          type="text"
          className="form-control"
          maxLength={1}
          value={digit}
          onChange={(event) => handleInputChange(index, event)}
          onKeyDown={(event) => handleKeyDown(index, event)}
          onPaste={(event) => handlePaste(event)}
          ref={(input) => (inputsRef.current[index] = input)}
          style={{
            margin: "5px",
            fontSize: "20px",
            textAlign: "center",
            borderRadius: "4px",
            border: "1px solid #ccc",
          }}
        />
      ))}
    </>
  );
};

export default OTPInput;
