import React from "react";
import PrimaryInput from "../../common/InputField/PrimaryInput";
import PrimaryDropdown from "../../common/InputField/PrimaryDropdown";
import Error from "../../common/Error/Error";
import SecondaryInput from "../../common/InputField/SecondaryInput";

const buildForm = (fields) => {
  return fields.map((field) => {
    const { type, name, value, placeholder, error, handler, className, groupClassName, options, required, ...rest } =
      field;

    switch (type) {
      case "dropdown":
        return (
          <div key={name}>
            <PrimaryDropdown
              name={name}
              value={value}
              options={options}
              onChange={handler}
              className={className}
              groupClassName={groupClassName}
              required={required}
              {...rest}
            />
            <Error message={error} />
          </div>
        );
      case "textarea":
        return (
          <div key={name}>
            <SecondaryInput
              name={name}
              placeholder={placeholder}
              onChange={handler}
              className={className}
              groupClassName={groupClassName}
              required={required}
            />
            <Error message={error} />
          </div>
        );
      default:
        return (
          <div key={name}>
            <PrimaryInput
              type={type}
              value={value}
              name={name}
              placeholder={placeholder}
              onChange={handler}
              className={className}
              groupClassName={groupClassName}
              required={required}
            />
            <Error message={error} />
          </div>
        );
    }
  });
};

export default buildForm;
