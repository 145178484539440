import React, { useState } from "react";
import PrimaryButton from "../../common/Button/PrimaryButton";
import buildForm from "../../utilities/form-helpers/FormBuilder";
import { useDispatch, useSelector } from "react-redux";
import { contactUs } from "../../redux/actions/contacts/contactActions";
import { toast } from "react-toastify";
import Spinner from "../../common/Spinner/Spinner";

const ContactUsForm = () => {
  const [contactData, setContactData] = useState({
    name: "",
    email: "",
    description: "",
    mobileNumber: "",
    subject: "",
  });

  const { contacts, loading, error } = useSelector((state) => state.contacts);

  const options = [
    { value: "Reasons", label: "Reasons" },
    { value: "Notes", label: "Notes" },
  ];

  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fields = [
    {
      className: "form-field",
      groupClassName: "form-input",
      type: "text",
      name: "name",
      placeholder: "Name",
      value: contactData.name,
      handler: handleChange,
    },
    {
      className: "form-field",
      groupClassName: "form-input",
      type: "email",
      name: "email",
      placeholder: "Email",
      value: contactData.email,
      handler: handleChange,
    },
    {
      className: "form-field",
      groupClassName: "form-input",
      type: "text",
      name: "mobileNumber",
      placeholder: "Mobile Number",
      value: contactData.mobileNumber,
      handler: handleChange,
    },
    {
      className: "form-field",
      groupClassName: "form-select",
      type: "dropdown",
      value: contactData.subject,
      name: "subject",
      options: options,
      handler: handleChange,
    },
    {
      className: "form-field",
      groupClassName: "form-input",
      type: "textarea",
      name: "description",
      placeholder: "Description",
      cols: "30",
      rows: "4",
      value: contactData.description,
      handler: handleChange,
    },
  ];

  const submittable =
    contactData.name === "" &&
    contactData.email === "" &&
    contactData.mobileNumber === "" &&
    contactData.description === "" &&
    contactData.subject === ""
      ? false
      : true;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", contactData.name);
    formData.append("email", contactData.email);
    formData.append("description", contactData.description);
    formData.append("subject", contactData.subject);
    formData.append("mobile_no", contactData.mobileNumber);
    try {
      await dispatch(contactUs(formData));
      if (!error) {
        toast.success(contacts);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <form type="submit" onSubmit={handleSubmit}>
      {buildForm(fields)}
      <div className="m-t-30 form-action">
        <PrimaryButton type="submit" className="button" disabled={!submittable || loading}>
          {loading ? <Spinner /> : "Submit"}
        </PrimaryButton>
        <div className="mt-4">
          <strong>For more information call us on +91 8849586918</strong>
        </div>
      </div>
    </form>
  );
};

export default ContactUsForm;
