import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { GoogleMap, Marker } from "@react-google-maps/api";
import LinearProgress from "../Loader.js/LinearProgress";
import { RadiusZone } from "../../assets/gif";
import { ProductIcon } from "../../assets/images";
import { useLocation } from "react-router-dom";

const SearchProductModal = ({ show, hide, selected, progress, setProgress, selectedOption, selectedImage }) => {
  console.log(typeof selectedImage === "object", "selectedImage---");

  const [isProgressBarVisible, setIsProgressBarVisible] = useState(true);

  const location = useLocation();
  console.log(location.pathname.includes("/messages"));

  const handleProgressFinish = () => {
    // This function will be called when all cycles of the progress bar are completed.
    // You can perform any action or state update here.

    // For example, you can hide the progress bar when it's finished.
    setIsProgressBarVisible(false);
  };

  const customMarkerIconSize = {
    width: 200,
    height: 200,
  };

  const customMarkerIcon = {
    url: RadiusZone,
    scaledSize: new window.google.maps.Size(customMarkerIconSize.width, customMarkerIconSize.height),
    anchor: new window.google.maps.Point(customMarkerIconSize.width / 2, customMarkerIconSize.height / 2),
  };

  return (
    <Modal show={show} onHide={hide}>
      <div className="modal-content">
        <Modal.Body style={{ padding: 0 }}>
          <div className="map-container">
            <GoogleMap
              options={{ disableDefaultUI: true }}
              zoom={15}
              center={selected}
              mapContainerStyle={{ width: "100%", height: "400px" }}
            >
              {selected && (
                <Marker
                  position={selected}
                  // Use the custom marker icon here
                  icon={customMarkerIcon}
                />
              )}
            </GoogleMap>
          </div>
        </Modal.Body>
      </div>
      {isProgressBarVisible && (
        <LinearProgress onFinish={handleProgressFinish} progress={progress} setProgress={setProgress} />
      )}
      <div className="product-content" style={{ marginTop: "15px", display: "flex", justifyContent: "center" }}>
        <h5 style={{ fontWeight: "normal", fontSize: 18, marginBottom: "10px" }}>
          We are finding your service at your nearest area
        </h5>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {location.pathname.includes("/messages") ? (
          <img
            src={selectedImage ? selectedImage : ProductIcon}
            alt="Product"
            style={{ marginRight: "10px", width: "50px", height: "50px" }}
          />
        ) : (
          <img
            src={selectedImage ? URL.createObjectURL(selectedImage) : ProductIcon}
            alt="Product"
            style={{ marginRight: "10px", width: "50px", height: "50px" }}
          />
        )}
        <div>
          <h5>{selectedOption?.label}</h5>
          <p>Finding in {selected?.address}</p>
        </div>
      </div>
    </Modal>
  );
};

export default SearchProductModal;
