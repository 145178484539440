import { userLogin as userLoginService } from "../../../api/authentication/login";
import { authTypes } from "../../constants/authentication/authTypes";

export const userData = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: authTypes.USER_DATA,
      payload: payload,
    });
  };
};

export const userLogin = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: authTypes.AUTH_LOADING,
      });

      const user = await userLoginService(payload);
      dispatch({
        type: authTypes.AUTH_SUCCESS,
        payload: user,
      });
      userData();
    } catch (error) {
      console.log(error, "error");
      dispatch({
        type: authTypes.AUTH_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const updateToken = (token) => {
  return {
    type: authTypes.UPDATE_TOKEN,
    payload: token,
  };
};
