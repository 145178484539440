import React from "react";
import { Link } from "react-router-dom";

const ProductList = ({ productsList,products, selectedSubCategoryName , selectedId , hasmore , setProductPage , productsPage }) => {
  console.log(productsList,"eweweweewewewe")
  return (
    <div className="services-link-wrapper">
      <div className="heading-wrapper">
        <p className="heading">{selectedId ? selectedSubCategoryName :""}</p>
      </div>
      <ul className="list-style-none">
        { selectedId && productsList?.items && productsList?.items?.map((item, index) => (
          <>
            <li className="services-link-item active">
              <Link state={{ id: item?.sub_category_id }} to="/vendor-list" className="services-link">
                {item.name}
              </Link>
            </li>
          </>
        ))}
        {productsList?.items?.length <= 0 && "No Records Found"}
        {productsList?.has_more && productsList?.has_more === true && <li className="services-link-item " style={{cursor:"pointer"}} onClick={(e) =>{
          e.preventDefault();
          setProductPage((prev) =>(prev + 1 ))
        }}>See More</li> }
      </ul>
    </div>
  );
};

export default ProductList;
