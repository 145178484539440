import { useCallback, useEffect, useRef, useState } from "react";
import { apiClient } from "../../api/client";
import Pusher from "pusher-js";
import { getAuth, getAuthToken, getCurrentUser } from "../../utilities/auth-helpers/authHelpers";
import { ReactComponent as IconArrowDownRadius } from "../../assets/icons/arrow-down-radius.svg";
import { ReactComponent as IconAdd } from "../../assets/icons/icon-add.svg";
import moment from "moment";
import { ReactComponent as IconMSGCall } from "../../assets/icons/msg-call.svg";
import { ReactComponent as IconSatisfied } from "../../assets/icons/satisfied.svg";
import { ReactComponent as IconSend } from "../../assets/icons/send.svg";
import { ReactComponent as IconShare } from "../../assets/icons/share.svg";
import { ReactComponent as IconNotSatisfied } from "../../assets/icons/not-satisfied.svg";
import { ImgUserAvatar } from "../../assets/images";
import ChatGif from "../../assets/gif/chatGif.gif";
import { Link, useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import EmojiPicker from "emoji-picker-react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { vendorServiceStatus } from "../../api/chat/chat";
import RatingModal from "../../common/Modal/RatingModal";
import SearchProductModal from "../../common/Modal/SearchProductModal";
import { searchVendor } from "../../api/products/products";
import { toast } from "react-toastify";
import { getMessaging, onMessage } from "firebase/messaging";
import ImagePreviewModal from "../../common/Modal/ImageModal";

const MessagesMain = () => {
  const location = useLocation();
  const user = getCurrentUser();
  const [emojishow, setemojishow] = useState(false);
  const [ImageModal, setImageModal] = useState(false);
  const [ImageModalId, setImageModalId] = useState();
  const [searching, setSearching] = useState("");
  const [socketMsg, setSocketMsg] = useState("");
  const [myMessage, setMyMessage] = useState("");
  const [chatID, setChatID] = useState("");
  const [data, setData] = useState({});
  console.log(data, "data");
  const [Loading, setLoading] = useState(false);
  const [filterUser, setFilteruser] = useState("recent_chat");
  const [usersList, setUsersList] = useState({
    has_more: false,
    items: [],
  });
  console.log(usersList, "user-list");
  const [file, setFile] = useState({
    upload: "",
  });
  const [threadPage, setThreadPage] = useState(1);
  const [currentUser, setCurrentUser] = useState(0);
  const [vendorName, setVendorName] = useState("");
  const [requestID, setRequestID] = useState(location?.state?.requestId);
  const [chatLoader, setChatLoader] = useState(false);
  const [userDetail, setUserDetail] = useState({});
  const [chatPage, setChatPage] = useState(0);
  const [progressBar, setProgressBar] = useState(0);
  console.log(progressBar, "progressBar");
  const [selected, setSelected] = useState({
    lat: null,
    lng: null,
    address: "",
  });
  const [userChat, setUserChat] = useState({
    has_more: false,
    items: [],
  });
  const [show, setShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    label: "",
  });
  const [vendorResponse, setVendorResponse] = useState({});
  console.log(vendorResponse, "vendor-res---");

  const [isActive, setIsActive] = useState(false);
  console.log(currentUser, "current-user");
  const handleClick = () => {
    // 👇️ toggle
    setIsActive((isActive) => !isActive);

    // 👇️ or set to true
    // setIsActive(true);
  };
  let toggleclass = isActive ? "is-active" : "";
  const scrollDivRef = useRef(null);
  const sortedChatItems =
    userChat?.items?.length > 0 && userChat?.items?.sort((a, b) => moment(a?.created_at) - moment(b?.created_at));
  const buttongREF = useRef(null);

  useEffect(() => {
    setCurrentUser(location?.state?.id);
    setRequestID(location?.state?.requestId);
    if (location?.state?.searchFor) {
      handleSendMessage(
        location?.state?.id,
        "Search For " + location?.state?.searchFor + " , location:" + location?.state?.location
      );
    }
    if (location?.state?.file != null) {
      handleSendMessage(location?.state?.id, null, location?.state?.file);
    }
  }, [location?.state?.id]);

  useEffect(() => {
    threadList();
  }, [threadPage]);

  useEffect(() => {
    if (userDetail?.thread_id) {
      Pusher.logToConsole = true;
      let pusher = new Pusher("flashsearch", {
        channelAuthorization: {
          endpoint: "https://api.flashsearch.in/broadcasting/auth",
          headers: { Authorization: getAuthToken() },
        },
        wsHost: "socket.flashsearch.in",
        wsPort: 6002,
        forceTLS: false,
        encrypted: true,
        disableStats: true,
        enabledTransports: ["ws", "wss"],
        cluster: "us2",
      });
      const channel1 = pusher.subscribe(`private-meeting.room.${userDetail?.thread_id}`);
      channel1.bind("chat", function (data) {
        console.log(data.chatMessage.value, "data");
        setSocketMsg(data.chatMessage);
      });
      return () => {
        pusher.unsubscribe(`private-meeting.room.${userDetail?.thread_id}`);
      };
    }
  }, [chatID, currentUser, userDetail.thread_id, location.state.requestId, requestID]);

  useEffect(() => {
    if (chatPage > 0) {
      if (userChat?.has_more === true) {
        getUserChat();
      }
    }
  }, [chatPage]);

  useEffect(() => {
    if (sortedChatItems && chatPage === 0) {
      const scroll = scrollDivRef?.current?.scrollHeight - scrollDivRef?.current?.clientHeight;
      scrollDivRef.current.scrollTo(0, scroll);
    }
  }, [sortedChatItems]);

  console.log(currentUser, "currentUser");

  useEffect(() => {
    if (!!currentUser) {
      getUserChat();
    }
  }, [currentUser, location.state.requestId, requestID]);

  useEffect(() => {
    // if (searching != "") {
    threadList();
    // }
  }, [filterUser, searching]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        buttongREF.current.click();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (socketMsg)
      setUserChat((p) => ({
        ...p,
        items: userChat.items.concat(socketMsg),
      }));
  }, [socketMsg]);

  const threadList = async () => {
    setLoading(true);
    let url;
    if (location?.state?.id != undefined || location?.state?.id != null) {
      url = `/chat/threads?page=${threadPage}&type=${filterUser}&search=${searching}&thread_id=${location?.state?.id}`;
    } else {
      url = `/chat/threads?page=${threadPage}&type=${filterUser}&search=${searching}`;
    }
    await apiClient
      .get(url)
      .then((res) => {
        setLoading(false);
        if (threadPage > 0) {
          setUsersList((p) => ({ ...p, items: usersList?.items.concat(res?.data?.items) }));
        } else {
          setUsersList((p) => ({ ...p, items: res?.data?.items }));
        }
        // if (res?.data?.has_more) {
        setUsersList((p) => ({ ...p, has_more: res?.data?.has_more }));
        // }
      })
      .catch((err) => {
        if (err?.status === 401) {
          // navigate("/")
          localStorage.clear();
          window.location.replace("/");
        }
        setLoading(false);
      });
  };

  console.log(window.location, "location---");

  const handleTypeMessage = (e) => {
    setMyMessage(e.target.value);
  };

  const getUserChat = async () => {
    setChatLoader(true);
    await apiClient
      .get(`/chat/history/${currentUser}?page=${chatPage + 1}&search&request_id=${requestID}`)
      .then((res) => {
        setData(res?.data?.thread);
        setChatID(res?.data?.messages?.items[0]?.chat_room_id);
        setUserDetail(res?.data?.thread);
        setUserChat((p) => ({
          ...p,
          has_more: res?.data?.messages?.has_more,
        }));

        if (chatPage > 0) {
          setUserChat((p) => ({
            ...p,
            items: userChat.items.concat(res?.data?.messages?.items),
          }));
        } else {
          setUserChat((p) => ({
            ...p,
            items: res?.data?.messages?.items,
          }));
        }

        setChatLoader(false);
      })
      .catch((err) => {
        if (err?.status === 401) {
          // navigate("/")
          localStorage.clear();
          window.location.replace("/");
        }
        setChatLoader(false);
      });
  };

  useEffect(() => {
    if (data) {
      setSelected({ ...selected, lng: data.longitude, lat: data.latitude, address: data.location });
      setSelectedOption({ ...selectedOption, label: data?.product?.search_product });
    }
  }, [data]);

  // async function searchInChat() {
  //   setChatLoader(true);
  //   await apiClient
  //     .get(
  //       `/chat/history/${currentUser}?page=${searchChat?.pageNo + 1}&search=${searchChat?.searchmsg}&request_id=${
  //         location?.state?.requestId
  //       }`
  //     )
  //     .then((res) => {
  //       setChatID(res?.data?.messages?.items[0]?.chat_room_id);
  //       setUserDetail(res?.data?.thread);
  //       setUserChat((p) => ({
  //         ...p,
  //         has_more: res?.data?.data?.messages?.has_more,
  //       }));

  //       if (searchChat > 0) {
  //         setUserChat((p) => ({
  //           ...p,
  //           items: userChat.items.concat(res?.data?.data?.messages?.items),
  //         }));
  //       } else {
  //         setUserChat((p) => ({
  //           ...p,
  //           items: res?.data?.data?.messages?.items,
  //         }));
  //       }

  //       setChatLoader(false);
  //     })
  //     .catch((err) => {
  //       setChatLoader(false);
  //     });
  // }

  async function clearUnreadCount(index) {
    let arr = [];
    arr = usersList?.items;

    arr[index].unread_messages = 0;

    setUsersList((p) => ({ ...p, items: arr }));
  }
  const handleSendMessage = async (id, msg, file) => {
    const formData = new FormData();
    formData.append("to_user_id", id);
    if (msg != undefined && msg != null) {
      formData.append("type", "message");
      formData.append("chat", msg);
    } else if (file != null && file != undefined) {
      formData.append("type", "attachment");
      formData.append("attachment", file);
    } else {
      formData.append("type", "message");
      formData.append("chat", myMessage);
    }

    if (requestID) {
      formData.append("request_id", requestID);
    }

    try {
      const res = await apiClient.post("/chat", formData);

      if (res?.data) {
        setChatID(res.data.chat_room_id);
        setChatPage(0);
        setMyMessage("");
      }
    } catch (error) {
      if (error?.status === 401) {
        // navigate("/")
        localStorage.clear();
        window.location.replace("/");
      }
    }

    // setMyMessage("");
  };
  const searchUser = (e) => {
    setThreadPage(0);
    setSearching(e.target.value);
  };

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      // if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && usersList?.has_more) {
          setThreadPage((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [usersList?.has_more]
  );

  const navigate = useNavigate();

  const handleVendorSearch = async () => {
    // if (Notification.permission !== "granted") return;
    if (Number(vendorResponse?.data?.request_count) >= 4) return;
    const formData = new FormData();
    if (selectedOption) {
      formData.append("product", selectedOption?.label);
    }
    if (data?.product?.product_image) {
      formData.append("product_image", data?.product?.product_image);
    }
    formData.append("location", selected.address);
    formData.append("lat", selected.lat);
    formData.append("long", selected.lng);
    if (vendorResponse?.data?.id && vendorResponse?.data?.request_count <= 3) {
      formData.append("request_id", vendorResponse?.data?.id);
    }
    try {
      setProgressBar(0);
      const response = await searchVendor(formData);
      if (response?.data?.accepted_vendor_id !== 0) {
        setCurrentUser(response?.data?.accepted_vendor_id);
        setRequestID(response?.data?.id);
        if (response?.data?.search_product) {
          handleSendMessage(response?.data?.accepted_vendor_id, "Search For " + response?.data?.search_product);
        }
        handleClose();
        // if (location?.state?.file != null) {
        //   handleSendMessage(response?.data?.accepted_vendor_id, null, location?.state?.file);
        // }
        // navigate("/messages", {
        //   state: {
        //     id: response?.data?.accepted_vendor_id,
        //     searchFor: selectedOption.label,
        //     file: data?.product?.product_image,
        //     requestId: response?.data?.id,
        //   },
        // });
      }
      setVendorResponse(response);
    } catch (error) {
      if (error?.status === 401) {
        toast.error("Please Login to continue");
        window.location.reload();
        // dispatch(updateToken(token));
        localStorage.clear();
      }
    }
  };

  useEffect(() => {
    const messaging = getMessaging();
    const unsubscribe = onMessage(messaging, (payload) => {
      setCurrentUser(payload?.data?.from_user_id);
      setRequestID(payload?.data?.object_id);
      // if (response?.data?.search_product) {
      handleSendMessage(payload?.data?.object_id, "Search For " + selectedOption?.label);
      // }
      handleClose();
    });
    return () => {
      unsubscribe(); // This is important to unsubscribe when the component unmounts
    };
  }, []);

  useEffect(() => {
    if (Math.floor(progressBar) === 100 && Number(vendorResponse?.data?.accepted_vendor_id) === 0) {
      if (vendorResponse?.data?.id && vendorResponse?.data?.request_count < 3) {
        handleVendorSearch();
      }
      if (vendorResponse?.data?.id && vendorResponse?.data?.request_count >= 3) {
        toast.error("Vendor not found for this product");
        setTimeout(() => {
          setVendorResponse({});
          setProgressBar(0);
          handleClose();
        }, 2000);
      }
    }
  }, [progressBar, vendorResponse?.data?.request_count]);

  const isUserSatisfied = async (status, id) => {
    const formData = new FormData();
    formData.append("status", status);
    formData.append("request_id", id);
    try {
      const response = await vendorServiceStatus(formData);
      setVendorResponse(response);
      if (response.status === 200) {
        if (status === "not_satisfied") {
          handleShow();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [showRatingModal, setshowRatingModel] = useState(false);

  const handleCloseRating = () => {
    setshowRatingModel(false);
  };

  const handleOpenRating = () => {
    setshowRatingModel(true);
  };

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    // setVendorResponse({});
    setProgressBar(0);
  };

  console.log(userDetail, "userDetail");
  return (
    <>
      <div className="message-section section-gap">
        <div className="container">
          <div className="row">
            <div className="col col-12 col-lg-4 col-xl-3">
              {/* <LeftPanel  usersList={usersList} /> */}
              <div className="text-left left-panel-wrapper">
                <div
                  className={"d-flex align-items-center justify-content-between message-heading"}
                  onClick={handleClick}
                >
                  <h5 className="m-b-0 d-flex align-items-center">
                    Messages
                    <i className="m-l-6 icon">
                      <IconArrowDownRadius />
                    </i>
                  </h5>
                  <i className="icon icon-add">
                    <IconAdd />
                  </i>
                </div>

                <div className={`${toggleclass} message-listing`}>
                  <div className="message-search-box">
                    <input
                      type="text"
                      className="form-input"
                      placeholder="Search"
                      value={searching}
                      onChange={searchUser}
                      onBlur={(e) => {
                        e.preventDefault();
                        clearInterval();
                      }}
                    />
                  </div>
                  <div style={{ height: "55vh", "overflow-x": "hidden", "overflow-y": "scroll" }}>
                    {usersList?.items &&
                      usersList?.items?.length > 0 &&
                      usersList?.items?.map((item, index) => {
                        return (
                          <>
                            <div
                              className={`d-flex message-item  ${
                                currentUser == item?.to_user?.id && requestID == item?.request_id ? "active" : ""
                              }`}
                              onClick={(e) => {
                                e.preventDefault();
                                setRequestID(item?.request_id);
                                setCurrentUser(item?.user?.id !== user?.id ? item?.user?.id : item.to_user?.id);
                                setVendorName(item?.user?.id !== user?.id ? item?.user?.name : item.to_user?.name);
                                setChatPage(0);
                                // setSearchchat((p) => ({ ...p, pageNo: 0 }));
                                if (item?.unread_messages != 0) {
                                  clearUnreadCount(index);
                                }
                              }}
                              ref={lastBookElementRef}
                            >
                              <div className="has-img">
                                <img
                                  src={
                                    item?.user?.id !== user?.id
                                      ? item?.user?.profile_image
                                      : item.to_user?.profile_image
                                  }
                                  alt="A-1 Pan"
                                  title="A-1 Pan"
                                  width={48}
                                  height={48}
                                />
                              </div>
                              <div className="msg-info">
                                <p className="m-b-0 vendor-title">
                                  {item?.user?.id !== user?.id ? item?.user?.name : item.to_user?.name}
                                </p>
                                <span className="msg-time">12m</span>

                                <p className="m-b-8 meg-highlight">
                                  {item?.last_message?.type === "attachment" ? "Image" : item?.last_message?.message}
                                  <span className="m-l-10 msg-counter">{item?.user_un_read}</span>
                                </p>

                                {/* <span className="not-satisfy">Not Satisfy</span> */}
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
            <div className="col col-12 col-lg-8 col-xl-9">
              {currentUser != 0 && currentUser != undefined ? (
                <>
                  <div className="msg-right-panel right-panel-wrapper">
                    <div className="d-flex justify-content-between align-items-center chat-header">
                      <div className="d-flex">
                        <div className="m-r-16 vendor-img has-img">
                          <img
                            src={
                              userDetail?.user?.id !== user?.id
                                ? userDetail?.user?.profile_image
                                : userDetail?.to_user?.profile_image
                            }
                            alt="profile_img"
                            title="profile_img"
                            width={40}
                            height={40}
                          />
                        </div>
                        <div className="msg-info">
                          <p className="m-b-0 vendor-title">
                            {" "}
                            {userDetail?.user?.id !== user?.id ? userDetail?.user?.name : userDetail?.to_user?.name}
                          </p>
                          <p className="m-b-8 messenger-status">
                            {userDetail?.user?.id !== user?.id && (
                              <>
                                <span
                                  className={`messenger-status-dot ${
                                    userDetail?.user?.is_online === 1 ? "is-online" : "is-offline"
                                  }`}
                                ></span>
                                {userDetail?.user?.is_online === 1 ? "Online" : "Offline"}
                              </>
                            )}
                            {userDetail?.to_user?.id !== user?.id && (
                              <>
                                <span
                                  className={`messenger-status-dot ${
                                    userDetail?.to_user?.is_online === 1 ? "is-online" : "is-offline"
                                  }`}
                                ></span>
                                {userDetail?.to_user?.is_online === 1 ? "Online" : "Offline"}
                              </>
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex action-block">
                        <>
                          {userDetail?.accepted_vendor_id !== 0 && userDetail?.is_satisfied !== "satisfied" && (
                            <>
                              <button
                                onClick={() => isUserSatisfied("not_satisfied", requestID)}
                                className="m-r-15 d-flex align-items-center btn btn-not-satisfied"
                              >
                                <IconNotSatisfied className="m-r-8" />
                                Not Satisfied
                              </button>
                              <button
                                onClick={handleOpenRating}
                                className="m-r-15 d-flex align-items-center btn btn-satisfied"
                              >
                                <IconSatisfied className="m-r-8" />
                                Satisfied
                              </button>
                            </>
                          )}
                        </>
                        {userDetail?.to_user?.mobile_number ? (
                          <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={<Tooltip id="tooltip-top">{userDetail?.to_user?.mobile_number}</Tooltip>}
                          >
                            <Button className="d-flex align-items-center btn btn-call">
                              <IconMSGCall className="m-r-8" />
                              Call
                            </Button>
                          </OverlayTrigger>
                        ) : (
                          <Button className="d-flex align-items-center btn btn-call">
                            <IconMSGCall className="m-r-8" />
                            Call
                          </Button>
                        )}
                      </div>
                    </div>
                    <div
                      className="chat-body"
                      style={{
                        height: "55vh",
                        "overflow-x": "hidden",
                        "overflow-y": "scroll",
                      }}
                      ref={scrollDivRef}
                      onScroll={(e) => {
                        e.preventDefault();
                        if (scrollDivRef.current.scrollTop === 0) {
                          let p = chatPage;
                          setChatPage(p + 1);
                        }
                      }}
                    >
                      <div style={{ padding: "1rem" }}>
                        {chatLoader === true ? <div className="text-center"> Loading Messages..... </div> : ""}
                      </div>

                      {sortedChatItems &&
                        sortedChatItems.length > 0 &&
                        sortedChatItems?.map((item, index) => (
                          <>
                            {user.id === item?.user_id && (
                              <>
                                <div className="sender">
                                  <div className="profile-pic">
                                    <img
                                      src={user?.profile_image ? user?.profile_image : ImgUserAvatar}
                                      alt="Chat Avtar"
                                    />
                                  </div>
                                  <div className="message-wrapper">
                                    <div className="message">
                                      {item?.type === "attachment" ? (
                                        <img
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setImageModalId(item?.value);
                                            setImageModal(true);
                                          }}
                                          alt=""
                                          src={item?.value}
                                          height={200}
                                          width={200}
                                        />
                                      ) : (
                                        <p>{item?.value}</p>
                                      )}{" "}
                                    </div>
                                    <div className="time">
                                      {" "}
                                      {moment(item?.created_at).format("h:mm A")} -{" "}
                                      {moment(item?.created_at).format("Do MMM YYYY")}
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}

                            {item?.user_id !== user?.id && (
                              <>
                                <div className="receiver">
                                  <div className="profile-pic">
                                    <img
                                      src={
                                        userDetail?.to_user?.profile_image
                                          ? userDetail?.to_user?.profile_image
                                          : ImgUserAvatar
                                      }
                                      alt="Chat Avtar"
                                    />
                                  </div>
                                  <div className="message-wrapper">
                                    <div className="message">
                                      {item?.type === "attachment" ? (
                                        <img alt="" src={item?.value} height={200} width={200} />
                                      ) : (
                                        <p>{item?.value}</p>
                                      )}
                                    </div>
                                    <div className="time">
                                      {" "}
                                      {moment(item?.created_at).format("h:mm A")} -{" "}
                                      {moment(item?.created_at).format("Do MMM YYYY")}
                                    </div>
                                  </div>
                                </div>{" "}
                              </>
                            )}
                          </>
                        ))}
                    </div>
                    <div className="chat-footer send-message">
                      <div className="chat-box">
                        <i className="icon icon-share" style={{ cursor: "pointer" }}>
                          <label class="form-label icon icon-camera" for="avatar" style={{ cursor: "pointer" }}>
                            <IconShare />
                          </label>
                          <input
                            type="file"
                            accept="image/jpeg, image/png"
                            id="avatar"
                            accept="image/png,image/gif,image/jpeg,image/jpg,application/pdf,application/msword"
                            onChange={(e) => {
                              if (
                                !(
                                  e.target.files[0].type === "image/png" ||
                                  e.target.files[0].type === "image/jpg" ||
                                  e.target.files[0].type === "image/jpeg" ||
                                  e.target.files[0].type === "image/gif" ||
                                  e.target.files[0].type === "application/pdf" ||
                                  e.target.files[0].type === "application/msword" ||
                                  e.target.files[0].type ===
                                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                )
                              ) {
                                alert("Only images,pdf,doc,docx allowded");
                                return false;
                              }
                              if (e.target.files[0].size > 2097152) {
                                alert("Not More than 2 MB is allowded");
                                return false;
                              }

                              setFile((p) => ({
                                ...p,
                                upload: e.target.files[0],
                              }));

                              swal({
                                title: "Are you sure?",
                                text: `Are you sure to share Image  ${e.target.files[0].name} file !`,
                                icon: "warning",
                                dangerMode: true,
                                buttons: ["cancel", "ok"],
                              }).then((willDelete) => {
                                if (willDelete) {
                                  handleSendMessage(
                                    userDetail?.user?.id !== user?.id ? userDetail?.user?.id : userDetail?.to_user?.id,
                                    null,
                                    e.target.files[0]
                                  );
                                } else {
                                }
                              });
                            }}
                            style={{ display: "none" }}
                          />
                        </i>
                        <div className="input-wrapper">
                          <i className="icon icon-send">
                            <div
                              onClick={(e) => {
                                e.preventDefault();
                                if (myMessage.trim() != "") {
                                  handleSendMessage(
                                    userDetail?.user?.id !== user?.id ? userDetail?.user?.id : userDetail?.to_user?.id,
                                    myMessage,
                                    null
                                  );
                                }
                              }}
                              ref={buttongREF}
                            >
                              <IconSend />
                            </div>
                          </i>

                          <input
                            type="text"
                            name="chat-box"
                            className="form-input"
                            placeholder="Type Message"
                            onChange={handleTypeMessage}
                            value={myMessage}
                          />
                        </div>
                      </div>
                      {/* <div className="emoji-btn">
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              setemojishow(!emojishow);
                            }}
                             > emoji </button>
                          {emojishow === true ? (
                            <EmojiPicker
                              onEmojiClick={(e) => {
                                let msg = myMessage + e.emoji
                                setMyMessage(msg);
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </div> */}
                    </div>
                  </div>
                </>
              ) : (
                <div className="msg-right-panel right-panel-wrapper">
                  {" "}
                  <img className="w-100 h-50" src={ChatGif} alt="" />{" "}
                </div>
              )}
              {/* <RightPanel /> */}
            </div>
          </div>
        </div>
      </div>
      {showRatingModal && (
        <RatingModal
          isUserSatisfied={isUserSatisfied}
          vendorId={currentUser}
          vendorName={vendorName}
          requestID={requestID}
          user={user}
          hide={handleCloseRating}
          showRatingModal={showRatingModal}
        />
      )}

      {show && (
        <SearchProductModal
          selectedOption={selectedOption}
          show={handleShow}
          hide={handleClose}
          selected={selected}
          selectedImage={data?.product?.product_image}
          setProgress={setProgressBar}
          progress={progressBar}
          lat={data?.latitude}
          long={data?.longitude}
        />
      )}

      {ImageModal && (
        <ImagePreviewModal
          timeline={sortedChatItems}
          ImageModal={ImageModal}
          setImageModal={setImageModal}
          ImageModalId={ImageModalId}
          setImageModalId={setImageModalId}
        />
      )}
    </>
  );
};

export default MessagesMain;
