import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import CloseButton from "../Button/CloseButton";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../redux/actions/authentication/authActions";
import PrimaryButton from "../Button/PrimaryButton";
import Spinner from "../Spinner/Spinner";
import OTPInput from "../../pages/OTP";
import { toast } from "react-toastify";

const VerifyModal = ({ show, onHide, status }) => {
  const { userDatas, verify_token, loading, token, error } = useSelector((state) => state?.authentication);
  console.log(error, "error");
  console.log(verify_token, token, "Henlo");
  const dispatch = useDispatch();

  const [locationError, setLocationError] = useState(false);
  const [otp, setOTP] = useState(new Array(6).fill(""));
  const [shouldCloseModal, setShouldCloseModal] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userDatas?.latitude === null && userDatas?.longitude === null) return setLocationError(true);
    const formData = new FormData();
    formData.append("name", userDatas?.name);
    formData.append("email", userDatas?.email);
    formData.append("country_code", userDatas?.country_code);
    formData.append("mobile_number", userDatas?.mobile_number);
    formData.append("longitude", userDatas?.longitude);
    formData.append("latitude", userDatas?.latitude);
    formData.append("fcm_token", userDatas?.fcm_token);
    formData.append("device_type", userDatas?.device_type);
    formData.append("verify_token", verify_token);
    formData.append("otp", otp?.join()?.replace(/,/g, ""));
    try {
      dispatch(userLogin(formData));
      if (error) {
        setShouldCloseModal(false); // Set the flag to close the modal
      } else {
        setShouldCloseModal(true); // Set the flag to close the modal
      }
    } catch (error) {
      console.log(error, "error");
      // toast.error(error);
    }
  };

  const closeRef = useRef();

  const isToken = () => {
    if (token) {
      closeRef?.current?.click();
      onHide();
    }
  };

  useEffect(() => {
    if (shouldCloseModal) {
      closeRef?.current?.click();
      onHide();
      // Reset the shouldCloseModal flag
      setShouldCloseModal(false);
    }
  }, [shouldCloseModal, onHide]);

  useEffect(() => {
    if (token) {
      isToken();
    }
  }, [token]);

  return (
    <Modal show={show} onHide={onHide}>
      <form type="submit" onSubmit={handleSubmit}>
        <Modal.Body>
          <div className="modal-content">
            <Modal.Header>
              <Modal.Title>
                <div className="modal-header">
                  <h4 className="modal-title" id="examplesModalLabel">
                    We have sent code on your
                    <span> {userDatas?.mobile_number} </span>
                    Please Enter Code
                  </h4>
                  <CloseButton handleClose={onHide} ref={closeRef} />
                </div>
              </Modal.Title>
            </Modal.Header>

            <div className="mb-3">
              <div className="d-flex justify-content-between otp-code-block">
                <OTPInput length={6} setOTP={setOTP} otp={otp} />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <div>{locationError && <p className="text-danger">Please grant access to your location to proceed.</p>}</div>
          <PrimaryButton type="submit" disabled={loading} className="button" onClick={isToken}>
            {loading ? <Spinner /> : "Submit"}
          </PrimaryButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default VerifyModal;
