import React from 'react'
import {IconStar} from '../../assets/images'

const TestimonialItem = ({testimonial, author, company, avatar, heading}) => {
  return (
    <div className='testimonial-item'>
      <p className='testimonial-heading'>{heading}</p>
      <div className='d-flex align-items-center rating-wrapper'>
        {' '}
        <i className='icon'>
          <img src={IconStar} alt='Star' title='1' width={20} height={20} />
        </i>
        <i className='icon'>
          <img src={IconStar} alt='Star' title='2' width={20} height={20} />
        </i>
        <i className='icon'>
          <img src={IconStar} alt='Star' title='3' width={20} height={20} />
        </i>
        <i className='icon'>
          <img src={IconStar} alt='Star' title='4' width={20} height={20} />
        </i>
        <i className='icon'>
          <img src={IconStar} alt='Star' title='5' width={20} height={20} />
        </i>
      </div>
      <p className='testimonial-des'>{testimonial}</p>
      <div className='d-flex align-items-center author-wrapper'>
        <img src={avatar} alt='profile_image' width={60} height={60} />
        <div className='author-info'>
          <p>{author}</p>
          <span>{company}</span>
        </div>
      </div>
    </div>
  )
}

export default TestimonialItem
