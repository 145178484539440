import { fetchBlogs as fetchBlogsService, fetchBlogsById as fetchBlogByIdService } from "../../../api/blogs/blogs";
import { blogsTypes } from "../../constants/blogs/blogs";

export const fetchBlogs = (payload, page) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: blogsTypes.FETCH_BLOGS_LOADING,
      });

      const blogs = await fetchBlogsService(payload);
      dispatch({
        type: blogsTypes.FETCH_BLOGS_SUCCESS,
        payload: blogs,
        page,
      });
    } catch (error) {
      dispatch({
        type: blogsTypes.FETCH_BLOGS_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const fetchBlogsById = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: blogsTypes.FETCH_BLOG_BY_ID_LOADING,
      });

      const blogs = await fetchBlogByIdService(payload);
      dispatch({
        type: blogsTypes.FETCH_BLOG_BY_ID_SUCCESS,
        payload: blogs,
      });
    } catch (error) {
      dispatch({
        type: blogsTypes.FETCH_BLOG_BY_ID_FAILURE,
        payload: error.message,
      });
    }
  };
};
