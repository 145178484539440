import { productTypes } from "../../constants/products/products";

const initialState = {
  products: [],
  loading: false,
  error: null,
  has_more: false,
  page: 1,
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case productTypes.FETCH_PRODUCTS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        has_more: false,
      };
    case productTypes.FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.page === 1 ? action.payload.data : [...state.products, ...action.payload.data],
        loading: false,
        error: null,
        hasmore: action.payload.has_more,
      };
    case productTypes.FETCH_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default productsReducer;
