import { categoryTypes } from "../../constants/categories/categories";

const initialState = {
  categories: [],
  subCategory: [],
  loading: false,
  error: null,
  has_more: false,
  page: 1,
  subCategoryPage: 1,
};

const categoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case categoryTypes.FETCH_CATEGORY_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        has_more: false,
      };
    case categoryTypes.FETCH_CATEGORY_SUCCESS:
      // console.log(action?.payload?.has_more, 'priti')
      return {
        ...state,
        categories: action.page === 1 ? action.payload.data : [...state.categories, ...action.payload.data],
        loading: false,
        error: null,
        has_more: action.payload.has_more,
      };
    case categoryTypes.FETCH_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case categoryTypes.FETCH_SUBCATEGORY_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        subCategory: {},
        has_more: false,
      };
    case categoryTypes.FETCH_SUBCATEGORY_SUCCESS:
      console.log(state.subCategory, action.payload.data, "first");
      return {
        ...state,
        // subCategory: action.payload.data,
        subCategory:
          action.subCategoryPage === 1 ? action.payload.data : [...state.subCategory, ...action.payload.data],
        loading: false,
        error: null,
        has_more: action.payload.has_more,
      };
    case categoryTypes.FETCH_SUBCATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default categoriesReducer;
