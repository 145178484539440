import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LoginSection from "./LoginSection";
import { HeaderLogo, IconMessage, IconNotification } from "../../../assets/icons";
import { navLinks } from "../../../constants/Header/NavLinks";
import LogoutModal from "../../../common/Modal/LogoutModal";
import { userLogin } from "../../../redux/actions/authentication/authActions";
import { useDispatch, useSelector } from "react-redux";
import RegisterModal from "../../../common/Modal/RegisterModal";
import VerifyModal from "../../../common/Modal/VerifyModal";
import { ToastContainer } from "react-toastify";
import { getCurrentUser } from "../../../utilities/auth-helpers/authHelpers";

const Header = ({ loginModel, setLoginModel }) => {
  const currentUser = getCurrentUser();
  const navigate = useNavigate();
  const location = useLocation();
  const routerLink = location.pathname.split("/")[1];
  const dispatch = useDispatch();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [activeLink, setActiveLink] = useState(routerLink || "/");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [showNewModal, setShowNewModal] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  console.log(isUserLoggedIn, currentUser, "isUserLoggedIn");

  useEffect(() => {
    if (!!currentUser) {
      if (!!currentUser?.token) {
        console.log(currentUser?.token, 'token')
        setIsUserLoggedIn(true);
      } else {
        setIsUserLoggedIn(false);
      }
    } else {
      setIsUserLoggedIn(false);
    }
  }, [currentUser]);

  const handleCloseNewModal = () => {
    setLoggedIn(false);
    setShowNewModal(false);
  };

  const { user } = useSelector((state) => state.authentication);

  const token = localStorage.getItem("fs");

  const handleShow = () => {
    if (token === null) {
      setShowLoginModal(true);
    } else if (token !== null) {
      setShowLogoutModal(true);
    }
  };
  const handleCloseLogin = () => setShowLoginModal(false);
  const handleCloseLogout = () => setShowLogoutModal(false);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.log(error.message);
        }
      );
    } else {
      console.log("Geolocation is not supported by your browser.");
    }
  };

  useEffect(() => {
    getLocation();
  }, []);

  useEffect(() => {
    if (loginModel === true) {
      handleShow();
      setLoginModel(false);
    }
  }, [loginModel]);

  const handleNavLinkClick = (link) => {
    setActiveLink(link);
  };

  useEffect(() => {
    const routerLink = location.pathname.split("/")[1];
    setActiveLink(routerLink || "/");
  }, [location]);

  const renderNavLinks = () => {
    return navLinks.map((item) => (
      <li key={item.link} className={`${item.className} ${item.link === activeLink ? "active" : ""}`}>
        {item.title === "Login" ? (
          <a
            className="nav-link"
            onClick={(e) => {
              e.preventDefault();
              const sidebar = document.getElementById("navbarSupportedContent");
              if (sidebar) {
                sidebar.classList.remove("show"); // Directly remove the 'show' class
              }
            }}
          >
            <LoginSection handleShow={handleShow} />
          </a>
        ) : (
          <Link to={item.link} className="nav-link" onClick={() => handleNavLinkClick(item.link)}>
            {item.title}
          </Link>
        )}
      </li>
    ));
  };

  const handleLogin = (formData) => {
    dispatch(userLogin(formData));
  };

  const openVerificationModal = () => {
    if (user?.status === 200) {
      setShowLoginModal(false);
      setShowNewModal(true);
      setLoggedIn(true);
    }
  };

  useEffect(() => {
    openVerificationModal();
  }, [user?.data?.verify_token, user?.status]);

  return (
    <>
      <ToastContainer />
      <header className="main-header header">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between header-wrapper">
            <button
              className="navbar-toggler mobile-view"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="logo-wrapper">
              <Link to="/">
                <HeaderLogo />
              </Link>
            </div>
            <nav className="navbar navbar-expand-lg">
              <div className="container-fluid">
                <div className={`navbar-collapse nav-responsive`} id="navbarSupportedContent">
                  <ul className="navbar-nav m-0 me-auto mb-2 mb-lg-0">{renderNavLinks()}</ul>
                </div>
              </div>
            </nav>
            <div className="d-flex align-items-center header-right-side-wrapper">
              {isUserLoggedIn && (

              <div className="d-flex m-r-55 icon-wrapper">
                <div
                  className="icon-link m-r-15"
                  onClick={(e) => {
                    navigate("/messages", { state: { requestId: 0 } });
                  }}
                >
                  <i className="d-flex align-items-center justify-content-center icon icon-message">
                    <IconMessage />
                  </i>
                </div>
                <div
                  className="icon-link"
                  onClick={(e) => {
                    // if (!userLogiedin) {
                    //   handleShow();
                    //   return false;
                    // } else {
                    navigate("/notifications");
                    // }
                  }}
                >
                  <i className="d-flex align-items-center justify-content-center icon icon-notification">
                    <IconNotification />
                  </i>
                </div>
              </div>
              )}
              <LoginSection handleShow={handleShow} />

              {showLoginModal && (
                <RegisterModal
                  showLoginModal={showLoginModal}
                  hide={handleCloseLogin}
                  handleLogin={handleLogin}
                  latitude={latitude}
                  longitude={longitude}
                  getLocation={getLocation}
                />
              )}
              {loggedIn && showNewModal && (
                <VerifyModal show={showNewModal} onHide={handleCloseNewModal} status={user?.status} />
              )}
              {showLogoutModal && <LogoutModal hide={handleCloseLogout} showLogoutModal={showLogoutModal} />}
              {/* {showRatingModal && <RatingModal hide={handleCloseRating} showRatingModal={showRatingModal} />} */}
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
