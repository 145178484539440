import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import SponsoredAdSlide from "./SponsoredAdSlide";
import { adsServices } from "../../redux/actions/sponsoredAd/sponsoredAdActions";

const SponsoredAd = () => {
  const settings = {
    dots: false,
    infinite: false,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    className: "slick-col",
    dotsClass: "button__bar",
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          arrows: false,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const dispatch = useDispatch();

  const { ads } = useSelector((state) => state.sponsoredAd);

  useEffect(() => {
    dispatch(adsServices());
  }, []);

  return (
    <div className="sponsored-ad-section section-gap">
      <div className="container">
        <div className="sponsored-ad-wrapper">
          <div className="text-left m-b-26 heading-wrapper">
            <h3 className="m-b-0">Sponsored Ads</h3>
          </div>
          <div className="row">
            {ads?.length === 0 && <div style={{display:"flex", justifyContent:"center"}}><strong>No Ads Found</strong></div>}
            <Slider {...settings}>
              {ads?.map((ad) => (
                <div className="col col-3">
                  <SponsoredAdSlide
                    url={ad?.redirect_link}
                    src={ad?.image}
                    key={ad?.id}
                    alt="adImage"
                    title={ad?.name}
                    width={312}
                    height={226}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SponsoredAd;
