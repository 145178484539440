import { contactUs as contactUsService } from "../../../api/contacts/contact";
import { contactUsTypes } from "../../constants/contacts/contactUs";

export const contactUs = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: contactUsTypes.CONTACTUS_LOADING,
      });

      const contactData = await contactUsService(payload);
      dispatch({
        type: contactUsTypes.CONTACTUS_SUCCESS,
        payload: contactData,
      });
    } catch (error) {
      dispatch({
        type: contactUsTypes.CONTACTUS_FAILURE,
        payload: error.message,
      });
    }
  };
};
