import { bannerTypes } from "../../constants/banner/bannerTypes";

const initialState = {
  banners: [],
  loading: false,
  error: null,
};

const bannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case bannerTypes.FETCH_BANNER_MAIN_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case bannerTypes.FETCH_BANNER_MAIN_SUCCESS:
      console.log(action.payload)
      return {
        ...state,
        banners: action.payload,
        loading: false,
        error: null,
      };
    case bannerTypes.FETCH_BANNER_MAIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default bannerReducer;
