import React from "react";
import ImgBell from "../../assets/images/img-bell.png";
import ImgPot from "../../assets/images/img-pot.png";
import ImgLineTop from "../../assets/images/line-top.png";
import ImgLineBottom from "../../assets/images/line-bottom.png";

const ProgressIndicator = () => {
  return (
    <div className="progress-indicator-section section-gap">
      <div className="container">
        <div className="progress-indicator-wrapper">
          <div className="row justify-content-center">
            <div className="col col-4 col-md-3 progress-item">
              <div className="progress-item-inner">
                <h3>10+ K</h3>
                <p>Happy Users</p>
              </div>
            </div>
            <div className="col col-4 col-md-3 progress-item">
              <div className="progress-item-inner">
                <h3>2.5+ K</h3>
                <p>Verified Experts</p>
              </div>
            </div>
            <div className="col col-4 col-md-3 progress-item">
              <div className="progress-item-inner">
                <h3>15+ K</h3>
                <p>Categories & Products</p>
              </div>
            </div>
          </div>
          <div className="img-pot">
            <img src={ImgPot} alt="ImagePot" width={124} height={96} />
          </div>
          <div className="img-line-top">
            <img src={ImgLineTop} alt="ImagePot" width={270} height={107} />
          </div>
          <div className="img-line-bottom">
            <img src={ImgLineBottom} alt="ImagePot" width={294} height={97} />
          </div>
          <div className="img-bell">
            <img src={ImgBell} alt="ImageBell" width={331} height={123} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressIndicator;
