import React from 'react'
import MessagesMain from '../components/Messages'

const Messages = () => {

  return (
    <MessagesMain />
  )

}

export default Messages
