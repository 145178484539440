import { blogsTypes } from "../../constants/blogs/blogs";

const initialState = {
  blogs: [],
  blog:{},
  loading: false,
  error: null,
  has_more: false,
  page: 1,
};

const blogReducer = (state = initialState, action) => {
  switch (action.type) {
    case blogsTypes.FETCH_BLOGS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        has_more: false,
      };
    case blogsTypes.FETCH_BLOGS_SUCCESS:
      return {
        ...state,
        blogs: action.page === 1 ? action.payload.data : [...state.blogs, ...action.payload.data],
        loading: false,
        error: null,
        has_more: action.payload.has_more,
      };
    case blogsTypes.FETCH_BLOGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      case blogsTypes.FETCH_BLOG_BY_ID_LOADING:
        return {
          ...state,
          blog:{},
          loading: true,
          error: null,
        };
      case blogsTypes.FETCH_BLOG_BY_ID_SUCCESS:
        return {
          ...state,
          blog: action.payload,
          loading: false,
          error: null,
        };
      case blogsTypes.FETCH_BLOG_BY_ID_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
    default:
      return state;
  }
};

export default blogReducer;
