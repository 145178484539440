import { authTypes } from "../../constants/authentication/authTypes";

const initialState = {
  user: {},
  token: null,
  loading: false,
  error: null,
  userDatas: {},
  verify_token: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case authTypes.AUTH_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case authTypes.AUTH_SUCCESS:
      return {
        ...state,
        user: action.payload,
        token: action.payload.data.verify_token ? null : action.payload.data.token,
        verify_token: action.payload.data.verify_token,
        loading: false,
        error: null,
      };
    case authTypes.USER_DATA:
      return {
        ...state,
        userDatas: action.payload,
        loading: false,
        error: null,
      };
    case authTypes.AUTH_FAILURE:
      console.log('reducer', action.payload)
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case authTypes.UPDATE_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
