import { Modal } from "react-bootstrap";
import CloseButton from "../Button/CloseButton";
import { ReactComponent as IconStar } from "../../assets/icons/star.svg";
import { rating } from "../../api/rating/rating";

import PrimaryButton from "../Button/PrimaryButton";
import { useState } from "react";
import {toast, ToastContainer} from 'react-toastify';

function RatingModal({ hide, showRatingModal, user, requestID, vendorId, vendorName, isUserSatisfied }) {
  const [review, setReview] = useState("");
  const [ratingUser, setRatingUser] = useState(0);
  console.log(rating, 'rating')

  const handleStarClick = (selectedRating) => {
    // When a user clicks a star, update the rating state
    setRatingUser(selectedRating);
  };

  const renderStars = () => {
    const starArray = [];

    for (let i = 1; i <= 5; i++) {
      starArray.push(
        <i
          key={i}
          style={{
            cursor: 'pointer',
            color: i <= ratingUser ? 'gold' : 'gray',
          }}
          onClick={() => handleStarClick(i)}
        >
          &#9733;
        </i>
      );
    }

    return starArray;
  };

  const vendorRating = async () => {
    const formData = new FormData();
    formData.append("request_id", requestID);
    formData.append("rating", ratingUser);
    formData.append("review", review);
    formData.append("vendor_id", vendorId);
    try {
      const response = await rating(formData);
      if(response?.status === 200){
       await isUserSatisfied("satisfied", requestID)
        toast.success("Review Submitted Successfully")
      }
    } catch (error) {
      console.log(error)
    } finally {
      hide()
    }
  };

  const handleChange = (e) => {
    setReview(e.target.value);
  };
  return (
    // <ToastContainer />
    <Modal show={showRatingModal} onHide={hide} centered size="lg">
      <Modal.Header>
        <Modal.Title>
          <div className="modal-header">
            <h4 className="modal-title" id="exampleModalLabel">
              Hey, {user.name}. Do you want to give Review and Rating to{" "}
              <a style={{ color: "#F8A11F" }}> {vendorName}</a> ?
            </h4>
            <CloseButton handleClose={hide} />
          </div>
          <div className="text-center m-2">
            <textarea
              onChange={handleChange}
              value={review}
              className="form-control"
              placeholder="Enter Your Feedback"
              rows="6"
              cols="40"
            ></textarea>{" "}
          </div>

          <div className="text-center">
            <h5 className="modal-title">Give Rating</h5>
          </div>
          <div className="d-flex justify-content-center rating-stars mt-3" style={{ width: "100%" }}>
            {renderStars()}
          </div>

          <PrimaryButton onClick={vendorRating} type="submit" className="button w-100 mt-4">
            Submit
          </PrimaryButton>
        </Modal.Title>
      </Modal.Header>
    </Modal>
  );
}

export default RatingModal;
