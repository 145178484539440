import React from "react";
import Home from "../pages/Home";
import ContactUs from "../pages/ContactUs";
import ListOfVendor from "../pages/ListOfVendor";
import Messages from "../pages/Messages";
import Blog from "../pages/Blog";
import BlogDetails from "../pages/BlogDetails";
import ShopDetails from "../pages/ShopDetails";
import ListYourBusiness from "../pages/ListYourBusiness";
import About from "../pages/About";
import Terms from "../pages/Terms";
import PrivacyPolicyMain from "../pages/PrivacyPolicy";
import Notifications from "../pages/Notifications";
import NotFound from "../components/NotFound/NotFound";
import Header from "../components/common/header/header";
import Footer from "../components/common/footer/footer";

export const Router = [
  {
    path: "/",
    exact: true,
    children: [
      { path: "/", element: <Home /> },
      {
        path: "contact-us",
        element: (
          <>
            <Header /> <ContactUs /> <Footer />
          </>
        ),
      },
      {
        path: "vendor-list",
        element: (
          <>
            <Header /> <ListOfVendor /> <Footer />
          </>
        ),
      },
      {
        path: "messages",
        element: (
          <>
            <Header /> <Messages /> <Footer />
          </>
        ),
      },
      {
        path: "blog",
        element: (
          <>
            <Header /> <Blog /> <Footer />
          </>
        ),
      },
      {
        path: "blog/:id",
        element: (
          <>
            <Header /> <BlogDetails /> <Footer />
          </>
        ),
      },
      {
        path: "shop-detail",
        element: (
          <>
            <Header /> <ShopDetails /> <Footer />
          </>
        ),
      },
      {
        path: "list-your-business",
        element: (
          <>
            <Header />
            <ListYourBusiness />
            <Footer />
          </>
        ),
      },
      {
        path: "about-us",
        element: (
          <>
            <Header /> <About /> <Footer />
          </>
        ),
      },
      {
        path: "terms",
        element: (
          <>
            <Header /> <Terms />
            <Footer />
          </>
        ),
      },
      {
        path: "privacy-policy",
        element: (
          <>
            <Header /> <PrivacyPolicyMain /> <Footer />
          </>
        ),
      },
      {
        path: "notifications",
        element: (
          <>
            <Header /> <Notifications />
            <Footer />
          </>
        ),
      },
      {
        path: "*",
        element: (
          <>
            <Header /> <NotFound />
            <Footer />
          </>
        ),
      },
    ],
  },
];
