import { Modal } from "react-bootstrap";

import Permission from "../../assets/images/permission.jpg";

function AccessModal({ hide, showNotificationModal, notificationPopUp }) {
  return (
    <Modal show={showNotificationModal} centered size="lg">
      <div className="text-center m-5">
        <img src={Permission} />
        <h6 style={{ fontWeight: "600" }}>Please allow push notification to notify vendors </h6>
        <span style={{ color: "grey" }}>You can turn off your notification anythime you want.</span>
        <br />
        <button onClick={notificationPopUp} className="btn btn-primary text-white my-3">
          {" "}
          Allow Notification
        </button>
        <br />
        {/* <a>Continue without microphone and camera</a> */}
      </div>
    </Modal>
  );
}

export default AccessModal;
