import { apiClient } from "../client";
import handleError from "../../utilities/api-helpers/handleError";
import tcWrap from "../../utilities/generic-helpers/tcWrap";
import {setAuth} from '../../utilities/auth-helpers/authHelpers';

export const userLogin = (payload) =>
  tcWrap(async () => {
    const data = await apiClient.post("/user/register", payload).catch(handleError);
    localStorage.setItem("user",JSON.stringify(data?.data))
    if(data?.data?.token) setAuth(data?.data?.token)
    return data;
  });
