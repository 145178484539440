import React from "react";
import Skeleton from "react-loading-skeleton";

const BlogLoading = ({count, height, width, style}) => {
  return (
    <div className="m-b-10">
      <Skeleton height={height} width={width} count={count} style={style} />
    </div>
  );
};

export default BlogLoading;
