const tcWrap = (f, errHandler) => {
  try {
    return f.apply(this);
  } catch (e) {
    if (errHandler) errHandler();
    else console.error(e);
    throw new Error(e?.message);
  }
};

export default tcWrap;
