import { sponsoredAdTypes } from "../../constants/sponsoredAd/sponsoredAdTypes";

const initialState = {
  ads: [],
  loading: false,
  error: null,
};

const sponsoredAdReducer = (state = initialState, action) => {
  switch (action.type) {
    case sponsoredAdTypes.FETCH_BANNER_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case sponsoredAdTypes.FETCH_BANNER_SUCCESS:
      return {
        ...state,
        ads: action.payload.data,
        loading: false,
        error: null,
      };
    case sponsoredAdTypes.FETCH_BANNER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default sponsoredAdReducer;
