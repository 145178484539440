import React from "react";
import ContactUs from "./ContactUs";

const ContactUsMain = () => {

  return (
    <>
      <ContactUs />
    </>
  );
};

export default ContactUsMain;
