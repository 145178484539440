import { apiClient } from "../client";
import handleError from "../../utilities/api-helpers/handleError";
import tcWrap from "../../utilities/generic-helpers/tcWrap";

export const fetchBlogs = (payload) =>
  tcWrap(async () => {
    const data = await apiClient.post("/blog/blog_listing", payload).catch(handleError);
    return data;
  });

  export const fetchBlogsById = (payload) =>
  tcWrap(async () => {
    const data = await apiClient.post("/blog/blog_detail", payload).catch(handleError);
    return data;
  });
